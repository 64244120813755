import React from "react";
import { Link } from "react-router-dom";

const ExecutiveSearch = () => {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-120px" }}>
                  <div
                    class="col-lg-6 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white", fontWeight: "300" }}>
                            Executive Search Firm
                          </h2>
                          <h3 style={{ color: "white" }}>
                            Build your core team with our leadership hiring
                            solutions.
                          </h3>
                          <p style={{ color: "black" }}>
                            We have been pioneers in hiring strong talent and
                            understanding the dynamics of every industry we find
                            ourselves in. Our record of having helped various
                            Fortune 500 companies to fill their company
                            executive talent pool speaks for itself.
                          </p>{" "}
                          <br /> <br />
                          <div className="row ml-1 pb-2">
                            <Link to="" className="class_link">
                              <div className="viewmore p-4 m-1 ">
                                <a href="">Get Hired</a>
                              </div>
                            </Link>
                            <Link to="" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Talk to Our Advisor</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/banner/excutive-search.jpg"></img>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- service-details --> */}
      <section class="service-details">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-8 col-md-12 col-sm-12 content-side">
              <div class="service-details-content">
                <div class="content-one">
                  <div class="sec-title">
                    <h2>
                      Executive search firm with industry-oriented solutions
                    </h2>
                  </div>
                  <figure class="image-box">
                    <img
                      src="assets/images/banner/excutive-search-1.jpg"
                      alt=""
                    />
                  </figure>
                  <div class="text">
                    <p>
                      In today’s ever-growing competitive market, hiring and
                      retaining quality talent has become extremely challenging.
                      A good understanding of the needs of a client can fetch
                      you outstanding results. With over 1000+ top executive
                      search firms in India, you might wonder what sets us
                      apart! Here’s why we are the best executive search
                      consultant for you!
                    </p>
                    <p>
                      we possess a deep understanding of the client’s business
                      requirements, industry dynamics and functional know-how.
                      Our access to a wide database network enables us to
                      provide you with the best of the best senior and top-level
                      executives for your business needs. We follow a thoroughly
                      thought-out process while searching for top-level
                      positions.
                    </p>
                  </div>
                </div>

                <div class="content-four">
                  <div class="upper-box">
                    <h3>How do we go about it?</h3>
                    <p>
                      we understand that organisations need leaders who can lead
                      by example, who have a vision, and who can help the
                      organisation grow and reach heights.
                    </p>
                  </div>
                  <div class="inner-box">
                    <div class="row clearfix">
                      <div class="col-lg-6 col-md-6 col-sm-12 column">
                        <ul class="list-item clearfix">
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-short-tie"></i>
                            </div>
                            <h5>Client Meeting</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                  A detailed discussion of the JD with the
                                  client about the search requirements,
                                  timelines and organizational strategy, to get
                                  an idea of the corporate culture followed by
                                  the signing of the agreement.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-money-bag"></i>
                            </div>
                            <h5>The Search</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                  earch for potential candidate profiles that
                                  fit the hiring client’s corporate culture and
                                  structure by targeting potential companies.
                                  Companies that are off-limits are de-lineated
                                  as well, to not upset any sensitive client
                                  relationships.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-google-maps"></i>
                            </div>
                            <h5>Research Strategy</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                  The team conducts original research to
                                  identify potential candidates’ profiles. A
                                  detailed discussion about the candidate’s
                                  position, role and responsibilities also
                                  happens with the Hiring Manager, followed by
                                  assessment.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 column">
                        <ul class="list-item clearfix">
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-hourglass"></i>
                            </div>
                            <h5>Qualifying Prospects</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                  After screening, a list of 25-30 candidates is
                                  shortlisted, followed by internal meetings.
                                  3-5 potential profiles are finalized for the
                                  role. We commence background checks at this
                                  stage and provide a detailed analysis of each
                                  candidate.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-lightbulb"></i>
                            </div>
                            <h5>Client Interview</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                  Our executive search firm then presents the
                                  client with the final list of top candidates,
                                  followed by which the client conducts
                                  interviews with each one of them to hire the
                                  best suitable executive for their role at the
                                  organization.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-google-maps"></i>
                            </div>
                            <h5>Negotiation & Hiring</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                  The process of salary and offer negotiation
                                  with the potential Senior Executives is
                                  initiated. With the assistance of the
                                  executive search firm, the onboarding of the
                                  top-level executive into the workplace takes
                                  place.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div class="service-sidebar">
                <div class="sidebar-widget categories-widget">
                  <ul class="categories-list clearfix">
                    <li>
                      <a href="temporary-staffing">Temprory Staffing</a>
                    </li>
                    <li>
                      <a href="direct-hire">Direct Hire</a>
                    </li>
                    <li>
                      <a href="contract-hire">Contract to Hire</a>
                    </li>
                    <li>
                      <a href="payrol-hire">Payrolling</a>
                    </li>
                    <li>
                      <a href="carporate-training">Corporate Training</a>
                    </li>
                    <li>
                      <a href="executive-search" class="active">
                        Executive Search
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="sidebar-widget recruitment-widget">
                  <div class="widget-content">
                    <div class="text">
                      <div
                        class="pattern-layer"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-43.png)",
                        }}
                      ></div>
                      <figure class="image-box">
                        <img src="assets/images/resource/men-1.png" alt="" />
                      </figure>
                      <h3>Recruitment Solutions for All Industries...</h3>
                    </div>
                    <div class="link">
                      <a href="#">
                        <i class="flaticon-right-arrow"></i>Place a Job Order
                      </a>
                    </div>
                  </div>
                </div>
                <div class="sidebar-widget subscribe-widget">
                  <h3>Reach Out</h3>
                  <form
                    // action=""
                    // method="post"
                    class="subscribe-form"
                  >
                    <div class="form-group">
                      <label>Your Name</label>
                      <input
                        type="text"
                        // name="name"
                        placeholder="Mr/Mrs.xxxxx"
                        required=""
                      />
                    </div>
                    <div class="form-group">
                      <label>Email Address</label>
                      <input
                        type="email"
                        // name="name"
                        placeholder="example@domain.com"
                        required=""
                      />
                    </div>

                    <div class="form-group message-btn">
                      <button type="submit" class="theme-btn-one">
                        Send Your Interest
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- service-details end --> */}
    </div>
  );
};

export default ExecutiveSearch;
