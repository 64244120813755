import React from "react";
import { Link } from "react-router-dom";

const Netdetails = () => {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-150px" }}>
                  <div
                    class="col-lg-7 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white", fontWeight: "300" }}>
                            Microsoft .NET Framework Certification Training
                          </h2>
                          <p style={{ color: "white", fontWeight: "200" }}>
                            Microsoft .NET Framework course is designed with the
                            help of top industry experts, making you proficient
                            in working with .NET for Web and Desktop,
                            understanding .NET Architecture, building dynamic
                            websites, and designing interactive Desktop/windows
                            applications with hands-on demonstrations. Enroll
                            now to become a Certified .NET Developer with
                            Ideants.
                          </p>{" "}
                          <br />
                          <div className="row ml-1 pb-2">
                            <Link to="" className="class_link">
                              <div className="viewmore p-4 m-1 ">
                                <a href="">View More</a>
                              </div>
                            </Link>
                            <Link to="" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Talk to Our Advisor</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/courses/dotnet-course.jpg"></img>
                     
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- React Course -details --> */}
      <section class="job-details">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-8 col-md-12 col-sm-12 content-side">
              <div class="job-details-content">
                <div class="text">
                  <h2>Course Description</h2>
                  <p>
                    In this course, you will learn the basics of .NET with C#.
                    You will also learn how .NET improves applications
                    architectural design and working compared to other
                    frameworks. You will build your own Console, Desktop and Web
                    applications using .NET and its libraries and tools,
                    securing them with Web Security features of .NET. This
                    course also gives you the working knowledge on how a Web
                    service is deployed and consumed by other Web applications.
                  </p>
                  <p>
                    .NET is the most popular framework for Web and Desktop
                    development over its contemporaries. The emergence of Web
                    services and C# in combination with Spring MVC makes it easy
                    to develop fast, reliable, and easily scalable applications
                    with other technology. The architecture of .NET also offers
                    the application to be well developed, maintained, and
                    updated with its corresponding dependencies.
                  </p>
                 
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div class="job-sidebar">
                <div
                  class=" form-column"
                  style={{
                    boxShadow: "0px 0px 1px 1px lightblue",
                    borderRadius: "10px",
                    borderTop: "10px solid #662d91",
                    paddingBottom: "3px",
                  }}
                >
                  <div class="form-inner" style={{ margin: "20px" }}>
                    <form
                      // method="post"
                      // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                      id="contact-form"
                      class="default-form"
                    >
                      <div class=" clearfix">
                        <h4 style={{ textAlign: "center" }}>
                          Free Career Counselling
                        </h4>
                        <p style={{ textAlign: "center" }}>
                          We are happy to help you 24/7
                        </p>

                        <div class=" form-group mt-3">
                          <input
                            type="text"
                            // name="username"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>

                        <div class=" form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Phone *"
                          />
                        </div>
                        <div class=" form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Email Id *"
                          />
                        </div>
                        
                        <div class=" form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Enroll Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 column">
              <div class="related-job">
                <h2 style={{ textAlign: "center" }}>
                  Why .Net Training from Ideants
                </h2>
              </div>
            </div>
            <section
              class="chooseus-section alternet-2 sec-pad-2"
              style={{ marginTop: "-130px" }}
            >
              <div class="auto-container">
                <div class="row clearfix">
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-44.png" alt=""></img></figure> */}
                        <h3>Live Interactive Learning</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> World-Class
                            Instructors
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Expert-Led
                            Mentoring Sessions
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Instant doubt
                            clearing
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="200ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-45.png" alt=""></img></figure> */}
                        <h3>Lifetime Access</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Course Access
                            Never Expires
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Free Access to
                            Future Updates
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Unlimited
                            Access to Course Content
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="400ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-46.png" alt=""></img></figure> */}
                        <h3>24x7 Live & desk Support.</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> One-On-One
                            Learning Assistance
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Help Desk
                            Support
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Resolve Doubts
                            in Real-time
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </section>
            <section
              class="chooseus-section alternet-2 sec-pad-2"
              style={{ marginTop: "-240px" }}
            >
              <div class="auto-container">
                <div class="row clearfix">
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-44.png" alt=""></img></figure> */}
                        <h3>Hands-On Project Based Learning</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i>{" "}
                            Industry-Relevant Projects
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Course Demo
                            Dataset & Files
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Quizzes &
                            Assignments
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="200ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-45.png" alt=""></img></figure> */}
                        <h3>Industry Recognised Certification</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Ideants
                            Training Certificate
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Graded
                            Performance Certificate
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Certificate of
                            Completion
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="400ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-46.png" alt=""></img></figure> */}
                        <h3>Opportunity in Top MNCs</h3>
                        <li>
                          <i class="flaticon-right-arrow"></i> .Net Developers
                        </li>
                        <li>
                          <i class="flaticon-right-arrow"></i> Web Developers
                        </li>
                        <li>
                          <i class="flaticon-right-arrow"></i> Full-stack
                          Developer
                        </li>
                        <br></br>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </section>
            <div
              class="col-lg-12 col-md-12 col-sm-12 column"
              style={{ marginTop: "-50px" }}
            >
              <div class="related-job">
                <h2 style={{ textAlign: "center" }}>
                  Instructor-led .Net live online Training Schedule & Flexible
                  batches for you
                </h2>
              </div>
            </div>
            <section
              class="about-section sec-pad"
              style={{ marginTop: "-100px" }}
            >
              <div class="pattern-layer"></div>
              <div class="auto-container">
                <div class="inner-container">
                  <div class="row clearfix">
                    <div
                      class="col-lg-4 col-md-12 col-sm-12 content-column"
                      style={{
                        lineHeight: "150%",
                        backgroundColor: "#fdf9f3",
                        borderRight: "3px solid #F8EEDF",
                      }}
                    >
                      <div
                        id="content_block_2"
                        style={{ padding: "30px", textAlign: "center" }}
                      >
                        <h4>
                          Price: ₹
                          <span style={{ textDecoration: "line-through" }}>
                            29,999
                          </span>
                        </h4>
                        <h1>₹24,999</h1>
                        <p>17% OFF , Save ₹5000.</p>
                        <br></br>
                        <h2>Duration: 45 Days</h2>
                        <h4>Aprill 22 - Orientation Class</h4>
                        <br></br>
                        <div class=" form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Enroll Now
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-12 col-sm-12 content-column">
                      <div id="content_block_3">
                        <div class="content-box">
                          <div class="sec-title">
                            <h3 style={{ fontSize: "42px" }}>
                              .Net Certification Training Course
                            </h3>
                            <hr />
                          </div>
                          <div
                            class="container-fluid"
                            style={{ width: "100%" }}
                          >
                            <div class="row">
                              <div class="col-md-6">
                                <ul>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> C#
                                    Programming Language Architecture
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> .NET
                                    Framework/.NET Core
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i>ASP.NET
                                    MVC/WebAPI
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Entity
                                    Framework (EF)
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i>SQL
                                    Server/Database Management
                                  </li>
                                </ul>
                              </div>
                              <div class="col-md-6">
                                <ul>
                                  <li>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Authentication and Authorization
                                  </li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i> Testing
                                    and Debugging
                                  </li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Deployment and DevOps
                                  </li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Performance Optimization and Scalability
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              class="about-section sec-pad"
              style={{ marginTop: "-190px", width: "100%" }}
            >
              <div class="pattern-layer"></div>
              <div class="auto-container">
                <div class="inner-container">
                  <div class="row clearfix">
                    <div
                      class="col-lg-6 col-md-12 col-sm-12 content-column"
                      style={{ borderRight: "2px solid lightgrey" }}
                    >
                      <div id="content_block_2" style={{ padding: "30px" }}>
                        <h3 style={{ textAlign: "center", fontSize: "36px" }}>
                          Skills Covered
                        </h3>
                        <hr></hr>

                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Introduction to
                            .NET 5.0
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> .NET -5.0
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Architecture of
                            .NET
                          </li>
                          <li>
                            {" "}
                            <i class="flaticon-right-arrow"></i>Common Language
                            Runtime
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Integrated
                            Development Environment
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Solution
                            Explorer & NuGet Package Manager
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                      <div id="content_block_3">
                        <div class="content-box">
                          <div class="sec-title">
                            <h3
                              style={{ textAlign: "center", fontSize: "36px" }}
                            >
                              Tools Covered
                            </h3>
                            <hr></hr>
                            
                            <img src="assets/images/courses/git.png"></img>
                            <img src="assets/images/courses/docker.png"></img>
                            {/* <img src="assets/images/courses/nginx.png"></img> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div class=" form-group message-btn centred">
                    <button
                      class="theme-btn-one"
                      type="submit"
                      // name="submit-form"
                    >
                      Short term Internship Program Details
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class=" form-group message-btn centred">
                    <button
                      class="theme-btn-one"
                      type="submit"
                      // name="submit-form"
                    >
                      Long term Internship Program Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <section
              class="faq-section text-left"
              style={{ width: "100%", marginTop: "-20px" }}
            >
              <div class="container-fluid text-left" style={{ width: "100%" }}>
                <div class="row">
                  <div class="col-md-8">
                    <section
                      class="btc-accordion text-left"
                      style={{ width: "100%" }}
                    >
                      <div
                        class="container text-left"
                        style={{ width: "100%" }}
                      >
                        <h1 class="text-center text-black">
                          Microsoft .NET Framework Certification Training
                          Curriculum
                        </h1>
                        <br />
                        <p class="text-center text-black">
                          <h4>Curriculum Designed by Experts</h4>{" "}
                        </p>

                        <div class="row">
                          <div class="col-md-12">
                            <div class="accordion mb-2" id="accordionExample">
                              <div class="card">
                                <div
                                  class="card-header collapsed"
                                  id="headingOne"
                                >
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                  >
                                    <h5 class="py-2">
                                      {" "}
                                      1. Introduction to .NET Framework (6
                                      Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseOne"
                                  class="collapse"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to .NET 5.0
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            .NET -5.0
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>
                                            Architecture of .NET
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Common Language Runtime
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Integrated Development Environment
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Solution Explorer & NuGet Package
                                            Manager
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Exploring Visual studio IDE and
                                              basic C# Console Applications
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              C# programming language
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingTwo">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    <h5 class="py-2">
                                      {" "}
                                      2. Introduction to C# and Oops using
                                      C#.NET (7 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseTwo"
                                  class="collapse"
                                  aria-labelledby="headingTwo"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            C# language Basics
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Arrays
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Conditional Statements
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Loops/Iterative statements
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Methods
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Oops in C#
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>
                                            Features of Oops
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Developing Console Applications
                                              Which imitates Phone book app in
                                              your smartphone using C# and many
                                              more
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              C# programming language and
                                              Object-Oriented programming
                                              concept
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingThree">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    <h5 class="py-2">
                                      3. Windows Forms using C#.Ne(4 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseThree"
                                  class="collapse"
                                  aria-labelledby="headingThree"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Handling Exceptions
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Delegates, Properties & Indexers
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Visual Studio-IDE for Windows Forms
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            GUI Controls & Event Handling
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Exceptional Handling.
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Different windows forms using
                                              different controls of toolbox
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              C# programming language
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingFour">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                  >
                                    <h5 class="py-2">
                                      4. Collections and Assemblies(5 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFour"
                                  class="collapse"
                                  aria-labelledby="headingFour"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Collections-Index based
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Collections, Interfaces &Generics
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Assembly & Types of Assemblies
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Install Assembly in GAC
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Consumer class Library
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Build and Deploy Collections in
                                              console & windows forms
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              C# programming language
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Classes essential for Collections
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingFive">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                  >
                                    <h5 class="py-2">
                                      5. ADO.NET- Data Access from SQL(3 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFive"
                                  class="collapse"
                                  aria-labelledby="headingFive"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            ADO.NET Introduction
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Models to connect to database
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Connecting to SQL server database
                                            using ADO.NET
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Build Login and Signup Windows
                                              application with MSSQL database
                                              connectivity
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Performing Create, Read, Update,
                                              Delete (CRUD) operations on SQL
                                              Server database from Windows app.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              ADO.NET library
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Classes essential for database
                                              connectivity
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingsix">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseSix"
                                    aria-expanded="false"
                                    aria-controls="collapseSix"
                                  >
                                    <h5 class="py-2">
                                      6.ASP.NET(3 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseSix"
                                  class="collapse"
                                  aria-labelledby="headingsix"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Web and Internet and Web
                                            applications
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Website, Web pages and Webpage
                                            elements
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            ASP.NET
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Taking steps towards web
                                              development by building Login and
                                              Registration web Applications and
                                              many more.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Web technologies, Websites, and
                                              elements of Website
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              ASP.NET for Web Development
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingSeven">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="collapseSeven"
                                  >
                                    <h5 class="py-2">
                                      7.Web Server Controls(3 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseSeven"
                                  class="collapse"
                                  aria-labelledby="headingSeven"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            HTML & Web Server Control
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Common Web Server Controls
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            ASP.NET Data Bound Controls
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              HTML Controls
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              ASP.NET for Web Development
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              ASP.NET data bound controls
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Data Bound Controls using Data
                                              base connectivity
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              HTML
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Data Bound Controls
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingEight">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseEight"
                                    aria-expanded="false"
                                    aria-controls="collapseEight"
                                  >
                                    <h5 class="py-2">
                                      8.LINQ, Web Architecture(3 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseEight"
                                  class="collapse"
                                  aria-labelledby="headingEight"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Getting started with LINQ
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            MVC and Entity Framework
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            3-tier web architecture
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Build and deploy MVC based and
                                              3-tier website applications and
                                              working with LINQ queries
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              LINQ
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              MVC
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Entity Framework
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              3-tier web architecture
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingNine">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseNine"
                                    aria-expanded="false"
                                    aria-controls="collapseNine"
                                  >
                                    <h5 class="py-2">
                                      9.State Management, Ajax(5 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseNine"
                                  class="collapse"
                                  aria-labelledby="headingNine"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to State Management
                                            techniques
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            View State, Query String &Cookie
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Cache & its types
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Application & Session
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Ajax introduction & Ajax Extensions
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Build and Deploy Websites using
                                              State management, Ajax programs
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              HTML
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              XML
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Ajax Extension controls
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingTen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseTen"
                                    aria-expanded="false"
                                    aria-controls="collapseTen"
                                  >
                                    <h5 class="py-2">
                                      10. Security and Web services(2 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseTen"
                                  class="collapse"
                                  aria-labelledby="headingten"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Web Services
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Web Security
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Implementing security in web
                                              applications.
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Build and Consume Custom made
                                              webservices
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Web security
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Web services
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="col-md-4">
                    <div
                      class=" form-column"
                      style={{
                        boxShadow: "0px 0px 1px 1px lightblue",
                        borderRadius: "10px",
                        borderTop: "10px solid #662d91",
                        paddingBottom: "3px",
                        width: "100%",
                        marginTop: "155px",
                      }}
                    >
                      <div class="form-inner" style={{ margin: "20px" }}>
                        <form
                          // method="post"
                          // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                          id="contact-form"
                          class="default-form"
                        >
                          <div class=" clearfix">
                            <h4 style={{ textAlign: "center" }}>
                              Free Career Counselling
                            </h4>
                            <p style={{ textAlign: "center" }}>
                              We are happy to help you 24/7
                            </p>

                            <div class=" form-group mt-3">
                              <input
                                type="text"
                                // name="username"
                                placeholder="Your Name *"
                                required=""
                              />
                            </div>

                            <div class=" form-group">
                              <input
                                type="text"
                                // name="phone"
                                required=""
                                placeholder="Phone *"
                              />
                            </div>
                            <div class=" form-group">
                              <input
                                type="text"
                                // name="phone"
                                required=""
                                placeholder="Email Id *"
                              />
                            </div>
                            {/* <div class=" form-group">
                    <input
                      type="text"
                      // name="location"
                      placeholder="Location "
                    />
                  </div>
                  <div class=" form-group">
                    <input
                      type="text"
                      // name="subject"
                      required=""
                      placeholder="Subject"
                    />
                  </div>
                  <div class=" form-group">
                    <textarea
                      // name="message"
                      placeholder="Message ..."
                    ></textarea>
                  </div> */}
                            <div class=" form-group message-btn centred">
                              <button
                                class="theme-btn-one"
                                type="submit"
                                // name="submit-form"
                              >
                                Enroll Now
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            
          </div>
        </div>
      </section>
      {/* <!-- React Course-details end --> */}
    </div>
  );
};

export default Netdetails;
