import React, { useState } from "react";
import "./Header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle("mobile-menu-visible");
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.classList.remove("mobile-menu-visible");
  };
  return (
    <div>
      <header className="main-header">
        <div className="header-lower">
          <div className="outer-box clearfix" id="header-outerBox">
            <div className="menu-area pull-left clearfix" id="headerBox">
              <div id="logo-area">
                <a href="/">
                  <img src="assets/images/zclogo.png" alt="logo" />
                </a>
              </div>

              {/* Mobile Navigation Toggler--> */}
              <div className="mobile-nav-toggler" onClick={toggleMenu}>
                <i className="icon-bar"></i>
                <i className="icon-bar"></i>
                <i className="icon-bar"></i>
              </div>
              <nav className="main-menu navbar-expand-md navbar-light">
                <div
                  className="collapse navbar-collapse show clearfix"
                  id="navbarSupportedContent"
                >
                  <ul className="navigation clearfix" style={{marginTop:"-10px"}}>
                    <li className="">
                      <a href="/">Home</a>
                    </li>
                    <li className="">
                      <a href="about-zeusCrew">About</a>
                    </li>
                    {/* <li className="">
                      <a href="employers">Employers</a>
                    </li> */}
                    <li className="">
                      <a href="services">Services</a>
                    </li>
                    {/* <li className="">
                      <a href="trainings">Carporate Training</a>
                    </li> */}
                    <li className="">
                      <a href="products">Products</a>
                    </li>
                    <li className="">
                      <a href="our-staffing-services">Staffing</a>
                    </li>
                    <li>
                      <a href="contacts">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <ul
              className="menu-right-content pull-right clearfix mt-1"
              id="menu-right-content"
            >
              <li>
                <a href="employers" className="theme-btn-one">
                Employers
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!--sticky Header   by SSS230--> */}
        <div className="sticky-header">
          <div className="outer-box clearfix">
            <div className="menu-area pull-left">
              <figure
                className="figuews"
                style={{
                  background: "#f0e6e0",
                  width: "250px",
                  height: "80px",
                  borderRadius: "0px 0 32px 0",
                  // marginBottom: "10px",
                  marginLeft: "-50px",
                  paddingTop: "10px",
                }}
              >
                <a href="/">
                  <img src="assets/images/zclogo1.png" alt="sticky logo" />
                </a>
              </figure>
              <nav className="navigations clearfix">
                <li className="">
                  <a href="/">Home</a>
                </li>
                <li className="">
                  <a href="about-zeusCrew">About</a>
                </li>
                {/* <li className="">
                  <a href="employers">Employers</a>
                </li> */}
                <li className="">
                  <a href="services">Services</a>
                </li>
                {/* <li className="">
                  <a href="trainings">Carporate Training</a>
                </li> */}
                <li className="">
                  <a href="products">Products</a>
                </li>
                <li className="">
                  <a href="our-staffing-services">Staffing</a>
                </li>
                <li>
                  <a href="contacts">Contact Us</a>
                </li>
              </nav>
            </div>
            <ul className="menu-right-content pull-right clearfix">
              <li>
                <a href="employers" className="theme-btn-one buttonSticky">
                 Employers
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      {/* Mobile menu  */}
      <div className={`mobile-menu ${isMenuOpen ? "mobile-menu-visible" : ""}`}>
        <div className="menu-backdrop"></div>
        <div className="close-btn" onClick={closeMenu}>
          <i className="fas fa-times"></i>
        </div>
        <nav className="menu-box">
          <div className="menu-outer">
            <ul className="navigation clearfix">
              <li style={{marginTop:'50px'}}>
                <a href="/">
                  <img src="assets/images/zc-logo1.jpeg" alt="sticky logo" />
                </a>
              </li>

              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="about-zeusCrew">About</a>
              </li>
              {/* <li>
                <a href="employers">Employers</a>
              </li> */}
              <li>
                <a href="services">Services</a>
              </li>
              {/* <li>
                <a href="trainings">Carporate Training</a>
              </li> */}
              <li className="">
                <a href="our-staffing-services">Staffing</a>
              </li>
              <li>
                <a href="contacts">Contact Us</a>
              </li>
              <li>
                <a href="employers">Employers</a>
              </li>
            </ul>
          </div>
          <div className="contact-info">
            <h4>Contact Info</h4>
            <ul>
              <li>
              #117,118, 6Th Floor, G & F Block, Neil Tower, Neil Rao Towers Road No.3, Vijayaanagar, EPIP Phase I, Whitefeild,Bangalore-560066,
              </li>
              <li>
                <a href="tel:+91 9032160768">+91 9032160768</a>
              </li>
              <li>
                <a href="mailto:info@zeuscrew.in">info@zeuscrew.in</a>
              </li>
            </ul>
          </div>
          <div className="social-links">
            <ul className="clearfix">
              <li>
                <a href="">
                  <span className="fab fa-twitter"></span>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="fab fa-facebook-square"></span>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="fab fa-pinterest-p"></span>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="fab fa-instagram"></span>
                </a>
              </li>
              <li>
                <a href="">
                  <span className="fab fa-youtube"></span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
