import React from "react";
import "./Slider.css";
import { Link } from "react-router-dom";

const Slider = () => {
  return (
    <div id="demo" className="carousel slide" data-ride="carousel">
      <ul className="carousel-indicators">
        <li data-target="#demo" data-slide-to="0" className="active"></li>
        <li data-target="#demo" data-slide-to="1"></li>
        <li data-target="#demo" data-slide-to="2"></li>
        <li data-target="#demo" data-slide-to="4"></li>
        <li data-target="#demo" data-slide-to="5"></li>
      </ul>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="overlay"></div> {/* Overlay added here */}
          <img
            src="assets/images/banner/banner-6.jpg"
            alt="Los Angeles"
            width="1100"
            height="500"
            style={{ objectFit: "cover" }}
          />
          {/* <!-- contactinfo-section --> */}
          <section class="contact-page-section">
            <div class="auto-container">
              <div class="row clearfix mb-5" style={{ marginTop: "-600px" }}>
                <div
                  class="col-lg-8 col-md-12 col-sm-12 content-column "
                  style={{ marginTop: "30px" }}
                >
                  <div id="content_block_6">
                    <div class="content-box">
                      <div class="sec-title sec-title1">
                        <h3
                          style={{
                            color: "white",
                            paddingTop: "150px",
                            fontSize: "36px",
                          }}
                        >
                         Transform your enterprise with Generative AI, driving innovation, automation, and growth.
                        </h3>
                        <p style={{ color: "#fff" }}>
                        Our AI services drive growth, automation, and transformation, delivering tailored generative AI solutions for enterprises.
                        </p>{" "}
                        <br /> <br /> <br />
                        {/* <div className="row ml-1 pb-2">
                          <Link to="job-seekers" className="class_link">
                            <div className="viewmore p-4 m-1 ">
                              <a href="">Get Hired</a>
                            </div>
                          </Link>
                          <Link to="products" className="class_link">
                            <div className="booknow p-4 m-1 ">
                              <a href="">Book An Exploratery Call</a>
                            </div>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 form-column form-column1">
                  <div
                    class="form-inner"
                    style={{
                      borderRadius: "12px",
                      marginTop: "120px",
                      padding: "2px",
                      backgroundColor: "04d3f480",
                    }}
                  >
                    <div>
                      <img
                        src="assets/images/banner/AI.jpg"
                        alt=""
                        style={{
                          objectFit: "cover",
                          height: "250px",
                          borderRadius: "12px 12px 0px 0px",
                          borderBottomColor:'red',
                          borderBottomWidth:5,
                        }}
                      ></img>
                    </div>
                    <h5
                      style={{
                        color: "#91298d",
                        paddingTop: "20px",
                        textAlign: "center",
                      }}
                    >
               Transformative Artificial Intelligence
                    </h5>
                    <br></br>
                    <Link to="#">
                    <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                      <button
                        class="theme-btn-one"
                        type="submit"
                        // name="submit-form"
                      >
                     Book An Exploratery Call
                      </button>
                    </div>
                    </Link>
                    
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- contactinfo-section end --> */}
        </div>
        <div className="carousel-item">
          <div className="overlay"></div> {/* Overlay added here */}
          <img
            src="assets/images/banner/banner-4.jpg"
            alt="Chicago"
            width="1100"
            height="500"
            style={{ objectFit: "cover" }}
          />
          {/* <!-- contactinfo-section --> */}
          <section class="contact-page-section">
            <div class="auto-container">
              <div class="row clearfix mb-5" style={{ marginTop: "-600px" }}>
                <div
                  class="col-lg-8 col-md-12 col-sm-12 content-column "
                  style={{ marginTop: "30px" }}
                >
                  <div id="content_block_6">
                    <div class="content-box">
                      <div class="sec-title sec-title1">
                        <h2
                          style={{
                            color: "white",
                            paddingTop: "150px",
                            fontWeight: "300",
                          }}
                        >
                        Grow your business with next-gen App Development Solutions.
                        </h2>
                        <p style={{ color: "#fff" }}>
                        Boost your business with innovative, scalable app development solutions designed to drive growth and enhance user experience.
                        </p>{" "}
                        <br /> <br /> <br />
                        {/* <div className="row ml-1 pb-2">
                          <Link to="/our-courses" className="class_link">
                            <div className="viewmore p-4 m-1 ">
                              <a href="">View Courses</a>
                            </div>
                          </Link>
                          <Link to="job-seekers" className="class_link">
                            <div className="booknow p-4 m-1 ">
                              <a href="">Get Hired</a>
                            </div>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 form-column form-column1">
                  <div
                    class="form-inner"
                    style={{
                      borderRadius: "12px",
                      marginTop: "120px",
                      padding: "2px",
                      backgroundColor: "04d3f480",
                    }}
                  >
                    <div>
                      <img
                        src="assets/images/banner/mobile-app.jpg"
                        alt=""
                        style={{
                          objectFit: "cover",
                          height: "250px",
                          borderRadius: "12px 12px 0px 0px",
                        }}
                      ></img>
                    </div>
                    <h5
                      style={{
                        color: "#91298d",
                        paddingTop: "20px",
                        textAlign: "center",
                      }}
                    >
                     Mobile Applications Development
                    </h5>
                    <br></br>
                    <Link to="#">
                      <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                        <button
                          class="theme-btn-one"
                          type="submit"
                          // name="submit-form"
                        >
                          Request For Quote
                        </button>
                      </div>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
          </section>





          
          {/* <!-- contactinfo-section end --> */}
        </div>

        <div className="carousel-item">
          <div className="overlay"></div> {/* Overlay added here */}
          <img
            src="assets/images/banner/banner-10.jpg"
            alt="Chicago"
            width="1100"
            height="500"
            style={{ objectFit: "cover" }}
          />
          {/* <!-- contactinfo-section --> */}
          <section class="contact-page-section">
            <div class="auto-container">
              <div class="row clearfix mb-5" style={{ marginTop: "-600px" }}>
                <div
                  class="col-lg-8 col-md-12 col-sm-12 content-column "
                  style={{ marginTop: "30px" }}
                >
                  <div id="content_block_6">
                    <div class="content-box">
                      <div class="sec-title sec-title1">
                        <h2
                          style={{
                            color: "white",
                            fontSize:'36px',
                            paddingTop: "150px",
                            fontWeight: "300",
                          }}
                        >
                      Digitalizing Businesses Globally, Your Trusted Partner in Custom Software Development
                        </h2>
                        <p style={{ color: "#fff" }}>
                        We specialize in custom software development to help businesses transform and grow in the digital world. Our solutions are designed to enhance efficiency, improve processes, and drive success.
                        </p>{" "}
                        <br /> <br /> <br />
                        {/* <div className="row ml-1 pb-2">
                          <Link to="/our-courses" className="class_link">
                            <div className="viewmore p-4 m-1 ">
                              <a href="">View Courses</a>
                            </div>
                          </Link>
                          <Link to="job-seekers" className="class_link">
                            <div className="booknow p-4 m-1 ">
                              <a href="">Get Hired</a>
                            </div>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 form-column form-column1">
                  <div
                    class="form-inner"
                    style={{
                      borderRadius: "12px",
                      marginTop: "120px",
                      padding: "2px",
                      backgroundColor: "04d3f480",
                    }}
                  >
                    <div>
                      <img
                        src="assets/images/banner/Custome-software-dev.jpg"
                        alt=""
                        style={{
                          objectFit: "cover",
                          height: "250px",
                          borderRadius: "12px 12px 0px 0px",
                        }}
                      ></img>
                    </div>
                    <h5
                      style={{
                        color: "#91298d",
                        paddingTop: "20px",
                        textAlign: "center",
                      }}
                    >
                    Web Applications Development
                    </h5>
                    <br></br>
                    <Link to="#">
                      <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                        <button
                          class="theme-btn-one"
                          type="submit"
                          // name="submit-form"
                        >
                          Request For Quote
                        </button>
                      </div>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
          </section>





          
          {/* <!-- contactinfo-section end --> */}
        </div>
        <div className="carousel-item">
          <div className="overlay"></div> {/* Overlay added here */}
          <img
            src="assets/images/banner/banner-11.jpg"
            alt="Chicago"
            width="1100"
            height="500"
            style={{ objectFit: "cover" }}
          />
          {/* <!-- contactinfo-section --> */}
          <section class="contact-page-section">
            <div class="auto-container">
              <div class="row clearfix mb-5" style={{ marginTop: "-600px" }}>
                <div
                  class="col-lg-8 col-md-12 col-sm-12 content-column "
                  style={{ marginTop: "30px" }}
                >
                  <div id="content_block_6">
                    <div class="content-box">
                      <div class="sec-title sec-title1">
                        <h2
                          style={{
                            color: "white",
                            fontSize:'40px',
                            paddingTop: "150px",
                            fontWeight: "300",
                          }}
                        >
                     Integrated Digital Marketing Services - Driving Through the Power of Data
                        </h2>
                        <p style={{ color: "#fff" }}>
                        Our integrated digital marketing services use data to drive smarter, more efficient strategies across all channels. By analyzing customer behavior and campaign performance
                        </p>{" "}
                        <br /> <br /> <br />
                        {/* <div className="row ml-1 pb-2">
                          <Link to="/our-courses" className="class_link">
                            <div className="viewmore p-4 m-1 ">
                              <a href="">View Courses</a>
                            </div>
                          </Link>
                          <Link to="job-seekers" className="class_link">
                            <div className="booknow p-4 m-1 ">
                              <a href="">Get Hired</a>
                            </div>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 form-column form-column1">
                  <div
                    class="form-inner"
                    style={{
                      borderRadius: "12px",
                      marginTop: "120px",
                      padding: "2px",
                      backgroundColor: "04d3f480",
                    }}
                  >
                    <div>
                      <img
                        src="assets/images/banner/digital-marketing.jpg"
                        alt=""
                        style={{
                          objectFit: "cover",
                          height: "250px",
                          borderRadius: "12px 12px 0px 0px",
                        }}
                      ></img>
                    </div>
                    <h5
                      style={{
                        color: "#91298d",
                        paddingTop: "20px",
                        textAlign: "center",
                      }}
                    >
                 Digital Marketing Services
                    </h5>
                    <br></br>
                    <Link to="#">
                      <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                        <button
                          class="theme-btn-one"
                          type="submit"
                          // name="submit-form"
                        >
                          Request For Quote
                        </button>
                      </div>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
          </section>





          
          {/* <!-- contactinfo-section end --> */}
        </div>



        <div className="carousel-item">
          <div className="overlay"></div> {/* Overlay added here */}
          <img
            src="assets/images/banner/banner-5.jpg"
            alt="New York"
            width="1100"
            height="500"
            style={{ objectFit: "cover" }}
          />
          {/* <!-- contactinfo-section --> */}
          <section class="contact-page-section">
            <div class="auto-container">
              <div class="row clearfix mb-5" style={{ marginTop: "-600px" }}>
                <div
                  class="col-lg-8 col-md-12 col-sm-12 content-column "
                  style={{ marginTop: "30px" }}
                >
                  <div id="content_block_6">
                    <div class="content-box">
                      <div class="sec-title sec-title1">
                        <h2
                          style={{
                            color: "white",
                            paddingTop: "150px",
                            fontWeight: "300",
                          }}
                        >
                          Recruit. Engage. Retain.
                        </h2>
                        <h3 style={{ color: "white" }}>
                          Enterprise Employee Lifecycle Solutions
                        </h3>
                        <p style={{ color: "#fff" }}>
                          We focus on connecting with talent, because we
                          understand the importance of a job in a person's life.
                          Focusing on the relationship with our talent will also
                          contribute to our clients' success
                        </p>{" "}
                        <br /> <br /> <br />
                        {/* <div className="row ml-1 pb-2">
                          <Link to="/our-courses" className="class_link">
                            <div className="viewmore p-4 m-1 ">
                              <a href="">View Courses</a>
                            </div>
                          </Link>
                          <Link to="employers" className="class_link">
                            <div className="booknow p-4 m-1 ">
                              <a href="">Request For Tallent</a>
                            </div>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 form-column form-column1">
                  <div
                    class="form-inner"
                    style={{
                      borderRadius: "12px",
                      marginTop: "120px",
                      padding: "2px",
                      backgroundColor: "04d3f480",
                    }}
                  >
                    <div>
                      <img
                        src="assets/images/banner/banner-item-3.jpg"
                        alt=""
                        style={{
                          objectFit: "cover",
                          height: "250px",
                          borderRadius: "12px 12px 0px 0px",
                        }}
                      ></img>
                    </div>
                    <h5
                      style={{
                        color: "#91298d",
                        paddingTop: "20px",
                        textAlign: "center",
                      }}
                    >
                      STAFFING AND HR SERVICES
                    </h5>
                    <br></br>
                    <Link to="#">
                      <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                        <button
                          class="theme-btn-one"
                          type="submit"
                          // name="submit-form"
                        >
                         Request For Tallent
                        </button>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- contactinfo-section end --> */}
        </div>
      </div>
      <a className="carousel-control-prev" href="#demo" data-slide="prev">
        <span className="carousel-control-prev-icon"></span>
      </a>
      <a className="carousel-control-next" href="#demo" data-slide="next">
        <span className="carousel-control-next-icon"></span>
      </a>
    </div>
  );
};

export default Slider;
