import React from "react";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
           {/* <!-- contactinfo-section --> */}
           <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-150px" }}>
                  <div
                    class="col-lg-6 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white",fontWeight:"300" }}>Partner for talent.</h2><h3 style={{color:"white"}}> Your specialised and equitable work partner</h3>
                          <p style={{ color: "black" }}>
                          We focus on connecting with talent, because we understand the importance of a job in a person's life.
                           Focusing on the relationship with our talent will also contribute to our clients' success
                          </p>{" "}
                          <br /> <br /> <br />
                          <div className="row ml-1 pb-2">
                            
                            <Link
                              to=""
                              className="class_link"
                            >
                              <div className="booknow p-4 m-1 ">
                                <a href="">Talk to Our Advisor</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        // boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                     <img src="assets/images/banner/services.jfif"></img>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
            
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- service-section --> */}
      <section class="service-section service-page">
        <div class="anim-icon">
          <div
            class="icon-1"
            style={{
              backgroundImage: "url(assets/images/icons/anim-icon-1.png)",
            }}
          ></div>
          <div class="icon-2"></div>
        </div>
        <div class="auto-container">
          <div class="sec-title centred">
            <span class="top-title">Services We Provide</span>
            <h2>Inspiring Staffing Services</h2>
            <p>
            Connecting top-tier talent with rewarding career opportunities, driving success for businesses and professionals alike.{" "}
              <br />
              readable content of a page.
            </p>
          </div>
          <div class="row clearfix">
            <div class="col-lg-4 col-md-6 col-sm-12 service-block">
              <div class="service-block-one">
                <div class="inner-box">
                  <figure class="image-box">
                    <img src="assets/images/service/service-1.jpg" alt="" />
                  </figure>
                  <div class="lower-content">
                    <div class="content-box">
                      <div class="inner">
                        <figure class="icon-box">
                          <img src="assets/images/icons/icon-4.png" alt="" />
                        </figure>
                        <h4>Temporary</h4>
                      </div>
                      <div class="link">
                        <a href="temporary-staffing">More Details</a>
                      </div>
                    </div>
                    <div class="overlay-content">
                      <p>
                      Providing flexible staffing solutions to meet short-term needs, ensuring businesses.
                      </p>
                      <a href="temporary-staffing">
                        <i class="flaticon-right-arrow"></i>More details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 service-block">
              <div class="service-block-one">
                <div class="inner-box">
                  <figure class="image-box">
                    <img src="assets/images/service/service-2.jpg" alt="" />
                  </figure>
                  <div class="lower-content">
                    <div class="content-box">
                      <div class="inner">
                        <figure class="icon-box">
                          <img src="assets/images/icons/icon-5.png" alt="" />
                        </figure>
                        <h4>Direct Hire</h4>
                      </div>
                      <div class="link">
                        <a href="direct-hire">More Details</a>
                      </div>
                    </div>
                    <div class="overlay-content">
                      <p>
                      Providing flexible staffing solutions to meet short-term needs, ensuring businesses.
                      </p>
                      <a href="direct-hire">
                        <i class="flaticon-right-arrow"></i>More details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 service-block">
              <div class="service-block-one">
                <div class="inner-box">
                  <figure class="image-box">
                    <img src="assets/images/service/service-3.jpg" alt="" />
                  </figure>
                  <div class="lower-content">
                    <div class="content-box">
                      <div class="inner">
                        <figure class="icon-box">
                          <img src="assets/images/icons/icon-6.png" alt="" />
                        </figure>
                        <h4>Contract</h4>
                      </div>
                      <div class="link">
                        <a href="contract-hire">More Details</a>
                      </div>
                    </div>
                    <div class="overlay-content">
                      <p>
                      Providing flexible staffing solutions to meet short-term needs, ensuring businesses.
                      </p>
                      <a href="contract-hire">
                        <i class="flaticon-right-arrow"></i>More details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 service-block">
              <div class="service-block-one">
                <div class="inner-box">
                  <figure class="image-box">
                    <img src="assets/images/service/service-4.jpg" alt="" />
                  </figure>
                  <div class="lower-content">
                    <div class="content-box">
                      <div class="inner">
                        <figure class="icon-box">
                          <img src="assets/images/icons/icon-61.png" alt="" />
                        </figure>
                        <h4>Payrolling</h4>
                      </div>
                      <div class="link">
                        <a href="payrol-hire">More Details</a>
                      </div>
                    </div>
                    <div class="overlay-content">
                      <p>
                      Providing flexible staffing solutions to meet short-term needs, ensuring businesses.
                      </p>
                      <a href="payrol-hire">
                        <i class="flaticon-right-arrow"></i>More details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 service-block">
              <div class="service-block-one">
                <div class="inner-box">
                  <figure class="image-box">
                    <img src="assets/images/service/service-5.jpg" alt="" />
                  </figure>
                  <div class="lower-content">
                    <div class="content-box">
                      <div class="inner">
                        <figure class="icon-box">
                          <img src="assets/images/icons/icon-62.png" alt="" />
                        </figure>
                        <h4>Carporate Training</h4>
                      </div>
                      <div class="link">
                        <a href="carporate-training">More Details</a>
                      </div>
                    </div>
                    <div class="overlay-content">
                      <p>
                      Providing flexible staffing solutions to meet short-term needs, ensuring businesses.
                      </p>
                      <a href="carporate-training">
                        <i class="flaticon-right-arrow"></i>More details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 service-block">
              <div class="service-block-one">
                <div class="inner-box">
                  <figure class="image-box">
                    <img src="assets/images/service/service-6.jpg" alt="" />
                  </figure>
                  <div class="lower-content">
                    <div class="content-box">
                      <div class="inner">
                        <figure class="icon-box">
                          <img src="assets/images/icons/icon-63.png" alt="" />
                        </figure>
                        <h4>Executive Search</h4>
                      </div>
                      <div class="link">
                        <a href="executive-search">More Details</a>
                      </div>
                    </div>
                    <div class="overlay-content">
                      <p>
                        Explain to you how this idea denouncing pleasure &
                        praising pain was born.
                      </p>
                      <a href="executive-search">
                        <i class="flaticon-right-arrow"></i>More details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- service-section end --> */}
      {/* <!-- hiring-section --> */}
      <section class="hiring-section">
        <div class="image-layer">
          <figure class="image-1">
            <img src="assets/images/resource/hiring-1.png" alt="" />
          </figure>
          <figure class="image-2">
            <img src="assets/images/resource/hiring-2.png" alt="" />
          </figure>
        </div>
        <div class="outer-container clearfix">
          <div class="left-column pull-left clearfix">
            <div class="inner-box pull-right">
              <div class="icon-box">   
                <i class="flaticon-factory"></i>
              </div>
              <h2 style={{textAlign:"right"}}>Industries <br></br>Hiring</h2>
              <p style={{textAlign:"right"}}> 
              Connecting skilled professionals with career opportunities across diverse industries, from technology and healthcare to finance and manufacturing.
              </p>
              <Link to="/employers"> <span  href="" >Industries</span> </Link>
            </div>
          </div>
          <div class="right-column pull-right clearfix">
            <div class="inner-box pull-left">
              <div class="icon-box">
                <i class="flaticon-working-man"></i>
              </div>
              <h2>Professions <br></br>Hiring</h2>
              <p>
              Matching skilled professionals with rewarding career opportunities across various fields, from IT and engineering to healthcare and finance.
              </p>
              <Link to="/employers"> <span href="/employers">Professions</span> </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- hiring-section end --> */}
    </div>
  );
};

export default Services;
