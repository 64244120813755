import React from "react";
import { Link } from "react-router-dom";

const Employers = () => {
  return (
    <div className="container my-2"> {/* Added container class for margin and centered content */}
      <div className="border">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>Employers</h2>
            </div>
             <div class="col-auto">
                <Link to="/home-add-employers" class="class_link">
                  <div class="viewmore p-4 ">
                    <a href="">Add Employers</a>
                  </div>
                </Link>
              </div>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">First</th>
              <th scope="col">Last</th>
              <th scope="col">Handle</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td colSpan="2">Larry the Bird</td>
              <td>@twitter</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Employers;
