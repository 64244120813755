import React from "react";

import { Link } from "react-router-dom";

const Temporarystaffing = () => {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-120px" }}>
                  <div
                    class="col-lg-6 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white",fontWeight:"300" }}>On Target, Temporary Staffing Small or Large-Scale</h2>
                          <p style={{ color: "black" }}>
                          As an industry leader in temporary staffing solutions for 6 years, we offer creative, scalable models 
                          to our clients that are tailor-made for their workforce requirements, with the assurance of full compliance with labour laws.  
                          </p>{" "}
                          <br /> <br /> 
                          <div className="row ml-1 pb-2">
                            <Link
                              to=""
                              className="class_link"
                            >
                              <div className="viewmore p-4 m-1 ">
                                <a href="">Get Hired</a>
                              </div>
                            </Link>
                            <Link
                              to=""
                              className="class_link"
                            >
                              <div className="booknow p-4 m-1 ">
                                <a href="">Talk to Our Advisor</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/banner/temparary.png"></img>
                     
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
            
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- service-details --> */}
      <section class="service-details">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-8 col-md-12 col-sm-12 content-side">
              <div class="service-details-content">
                <div class="content-one">
                  <div class="sec-title">
                    <h2>Temporary Staffing Services</h2>
                  </div>
                  <figure class="image-box">
                    <img src="assets/images/banner/temparary-1.png" alt="" />
                  </figure>
                  <div class="text">
                    <p>
                    Organizations, be they large- or small-scale, are largely dependent on manpower requirements. 
                    Generally, larger organizations have hiring needs for temporary/contract employees, 
                    on-time payroll, and compliance to be taken care of. How can you achieve this? By making us 
                    your temporary staffing partner! We’ll cater to your multiple requirements and design customizable, scalable solutions as per need.   
                    </p>
                    
                  </div>
                </div>
                
                
                <div class="content-four">
                  <div class="upper-box">
                    <h3>Our approach</h3>
                    <p>
                      Trouble that are bound to ensue; and equaly blame belongs
                      those who fail in their duty through weakness of will
                      which is the same as saying through shrinking from toil
                      and pain.
                    </p>
                  </div>
                  <div class="inner-box">
                    <div class="row clearfix">
                      <div class="col-lg-6 col-md-6 col-sm-12 column">
                        <ul class="list-item clearfix">
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-short-tie"></i>
                            </div>
                            <h5>Identifying and shortlisting </h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Our dedicated, expert team will get in touch with you to understand your workforce and manpower needs. It could be anything varying from top level, mid-level to junior-level candidate requirements. We populate the brief we send you with just the relevant information to guide us further in helping you. 
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-money-bag"></i>
                            </div>
                            <h5>Customized, scalable</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Based on your business needs, we’ll design a customizable model and form a strategy to meet your requirements. Our access to pre-validated candidates makes it easy for us to get a team together for your needs even at short notice. And most importantly, you can scale up or scale down on manpower needs.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-google-maps"></i>
                            </div>
                            <h5>Quality Candidates Access</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                We have access to a huge talent pool, sourced from various talent pipelines like online portals, recruitment drives, and social media. Our expert temporary staffing team screens and selects profiles that match your needs and conducts interviews to select the most suitable candidates.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 column">
                        <ul class="list-item clearfix">
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-hourglass"></i>
                            </div>
                            <h5>Onboarding process</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Every new hire feels out of place in a new environment, no matter their experience. We put in extra effort into our temporary staffing, helping your new hire settle in, once they are onboard. We work toward ensuring the team that we have put together achieves the goals you have set and the results you want to achieve.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-lightbulb"></i>
                            </div>
                            <h5>Payroll process and compliance</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Our payroll team processes timely payroll for every organization we work for. Our compliance team is up to date on the latest regulations governing corporate entities in India. We maintain statutory compliance for all our partners, and we stand by them in trying times guiding them through the regulatory landscape.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-google-maps"></i>
                            </div>
                            <h5>Handling the Clients queries</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                We are worried about our relationships with our clients and we manage them with utmost care and vigilance. A dedicated relationship manger and a team of people working under him are there for every account. This team will be at your disposal to handle any queries or questions that you may have.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div class="service-sidebar">
                <div class="sidebar-widget categories-widget">
                  <ul class="categories-list clearfix">
                    <li>
                      <a href="temporary-staffing" class="active">
                        Temprory Staffing
                      </a>
                    </li>
                    <li>
                      <a href="direct-hire">Direct Hire</a>
                    </li>
                    <li>
                      <a href="contract-hire">Contract to Hire</a>
                    </li>
                    <li>
                      <a href="payrol-hire">Payrolling</a>
                    </li>
                    <li>
                      <a href="carporate-training">Corporate Training</a>
                    </li>
                    <li>
                      <a href="executive-search">Executive Search</a>
                    </li>
                  </ul>
                </div>
                <div class="sidebar-widget recruitment-widget">
                  <div class="widget-content">
                    <div class="text">
                      <div
                        class="pattern-layer"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-43.png)",
                        }}
                      ></div>
                      <figure class="image-box">
                        <img src="assets/images/resource/men-1.png" alt="" />
                      </figure>
                      <h3>Recruitment Solutions for All Industries...</h3>
                    </div>
                    <div class="link">
                      <a href="#">
                        <i class="flaticon-right-arrow"></i>Place a Job Order
                      </a>
                    </div>
                  </div>
                </div>
                <div class="sidebar-widget subscribe-widget">
                  <h3>Reach Out</h3>
                  <form
                    // action=""
                    // method="post"
                    class="subscribe-form"
                  >
                    <div class="form-group">
                      <label>Your Name</label>
                      <input
                        type="text"
                        // name="name"
                        placeholder="Mr/Mrs.xxxxx"
                        required=""
                      />
                    </div>
                    <div class="form-group">
                      <label>Email Address</label>
                      <input
                        type="email"
                        // name="name"
                        placeholder="example@domain.com"
                        required=""
                      />
                    </div>
                   

                    <div class="form-group message-btn">
                      <button type="submit" class="theme-btn-one">
                        Send Your Interest
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- service-details end --> */}
    </div>
  );
};

export default Temporarystaffing;
