import React from "react";
import { Link } from "react-router-dom";
import Recruitment from "../Recruitment/Recruitment";

const Welcome = () => {
  return (
    <>
      <div>
        <section class="about-style-two">
          <h2 style={{ textAlign: "center", marginTop: "-50px" }}>
          Industry Leaders
          Revolutionizing Business Operations <br />with Generative AI Solutions
          </h2>
          <p style={{ textAlign: "center" }}>
          Industry leaders are transforming business operations with generative AI solutions, driving innovation and efficiency across sectors.<br /> By automating processes, enhancing decision-making, and creating personalised experiences, generative AI enables organisations to achieve <br /> unprecedented levels of performance and adaptability, setting new benchmarks for success.
          </p>
          <br></br>
          <div class="auto-container">
            <div class="inner-container">
              <div class="row clearfix">
                <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                  <div
                    class="card mb-3"
                    style={{
                      maxWwidth: "540px;",
                      padding: "0px",
                      backgroundColor: "#f2e9e3",
                    }}
                  >
                    <div class="row g-0">
                      <div
                        class="col-md-4"
                        style={{
                          backgroundColor: "#17a2b8b8 ",
                         
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        <img style={{height:'100%'}}
                          src="assets/images/certificate.png"
                          class="img-fluid rounded-start"
                          alt="..."
                        ></img>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h4 class="card-title">AI & ML Development</h4>
                          <p class="card-text">
                          AI and ML development enables smarter automation, data-driven insights, and innovative solutions for enhanced efficiency and growth.
                          </p>
                          <div className="link">
                            <a href="/services" style={{ fontSize: "18px" }}>
                              Explore Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                  <div
                    class="card mb-3"
                    style={{
                      maxWwidth: "540px;",
                      padding: "0px",
                      backgroundColor: "#f2e9e3",
                    }}
                  >
                    <div class="row g-0">
                      <div
                        class="col-md-4"
                        style={{
                          backgroundColor: "#662d9199 ",
                          
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        <img style={{height:"100%"}}
                          src="assets/images/certificate.-2.png"
                          class="img-fluid rounded-start"
                          alt="..."
                        ></img>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h4 class="card-title">
                          Cloud and DevOps Services
                          </h4>
                          <p class="card-text">
                          Cloud and DevOps services streamline software delivery, optimise workflows, and enhance scalability. By integrating cloud solutions with DevOps practices
                          </p>
                          <div className="link">
                            <a
                              href="/services"
                              style={{ fontSize: "18px" }}
                            >
                              Explore Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="welcome-section" style={{ marginTop: "-150px" }}>
          <div className="auto-container">
            <div className="sec-title centred">
              <span className="top-title">Welcome to Zeus Crew Services</span>
              <h2 style={{fontWeight:'300'}}>
                
                Accelerate Your Business Transformation <br></br>{" "}with
Custom Software Solutions
              </h2>
              <p>
              Transform your business with custom software solutions tailored to streamline operations, enhance customer experience, <br />and drive innovation. Embrace scalable and adaptable technology for lasting growth and success.
                
                
              </p>
            </div>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure className="image-box js-tilt">
                  <img src="assets/images/resource/welcome-1.png" alt="" />
                </figure>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div id="content_block_1">
                  <div className="content-box">
                    <div className="tabs-box">
                      <div className="tabs-content">
                        <div className="tab active-tab" id="tab-1">
                          <div className="inner-box">
                            <h2 style={{fontSize:'32px',fontWeight:'300'}}>
                            Custom Software Solutions to drive your niche & strategic business
                            </h2>
                            <p>
                              Discover top talent effortlessly with Ideants
                              Technologies: Your premier destination for hiring
                              your next standout candidate. Experience unmatched
                              efficiency and precision in your hiring process
                              with Zeus Crew Services at your side.
                            </p><p>Custom software solutions are designed to address the unique challenges of your niche and strategic business, enabling streamlined operations, improved efficiency, and enhanced customer experiences. By tailoring technology to your specific goals, these solutions foster innovation, scalability, and long-term growth, ensuring your business stays ahead in a competitive landscape.</p>
                            
                            
                          </div>
                        </div>
                        <div className="tab" id="tab-2">
                          <div className="inner-box">
                            <h5>Find Your Right Place</h5>
                            <h2>
                              Explore Your Career Path With Zeus Crew Services
                            </h2>
                            <p>
                              Embark on a journey of professional growth with
                              Zeus Crew Services, where your career
                              aspirations find their perfect match. Discover
                              endless opportunities and unlock your potential
                              with our tailored staffing solutions.
                            </p>
                            <ul className="list clearfix">
                              <li>
                                <figure className="icon-box">
                                  <img
                                    src="assets/images/icons/icon-71.png"
                                    alt=""
                                  />
                                </figure>
                                <h4>Executive Opportunities</h4>
                              </li>
                              <li>
                                <figure className="icon-box">
                                  <img
                                    src="assets/images/icons/icon-72.png"
                                    alt=""
                                  />
                                </figure>
                                <h4>Non Excecutive Opportunities</h4>
                              </li>
                            </ul>
                            <div className="link">
                              <a href="">
                                <i className="flaticon-right-arrow"></i>Your
                                Required Talent
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Recruitment /> */}
        {/* <!-- hiring-section --> */}
        <section class="hiring-section">
          {/* <div class="image-layer">
            <figure class="image-1">
              <img src="assets/images/resource/hiring-1.png" alt="" />
            </figure>
            <figure class="image-2">
              <img src="assets/images/resource/hiring-2.png" alt="" />
            </figure>
          </div> */}
          <div class="outer-container clearfix" style={{backgroundColor:'#462b34'}}>
             <div
                    className="auto-container pt-5 pb-5 "
                    
                  >
                    <div className="sec-title centred">
                      <h2 style={{color:'white',fontWeight:'300'}}>What Digital Marketing Can Do for Your Business</h2>
                      <p style={{color:'white'}}>
                        Digital marketing encompasses Search Engine Optimization (SEO),
                        Search Engine Marketing (SEM), Social Media Marketing (SMM),{" "}
                        <br></br>Pay-Per-Click Advertising (PPC), and much more. It’s one of
                        the most important services you can invest in for the <br></br>
                        success and long-term growth of your business.{" "}
                      </p>
                    </div>
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                        <figure className="image-box js-tilt">
                          <img
                            src="assets/images/courses/vlsi-physical-design.jpg"
                            alt="vlsi-certification"
                            style={{ borderRadius: "32px", border: "5px solid purple" }}
                          />
                        </figure>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div id="content_block_1">
                          <div className="content-box">
                            <div className="tabs-box">
                              <div className="tabs-content">
                                <div className="tab active-tab" id="tab-1">
                                  <div className="inner-box">
                                    <h2 style={{color:'white',fontWeight:'300'}}>Digital Marketing Agency </h2>
                                    <p style={{color:'white'}}>
                                      Partner with a leading digital marketing agency to
                                      increase brand awareness, reach your target audience &
                                      grow your business online.
                                    </p>
                                    <ul class="c-unordered__list list-unstyled" style={{color:'white',fontWeight:'300'}}>
                                      <li class="c-unordered__list-item text-big">
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                                        Search Engine Optimization (SEO)
                                      </li>
                                      <li class="c-unordered__list-item text-big">
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                                        Pay-Per-Click Advertising (PPC)
                                      </li>
                                      <li class="c-unordered__list-item text-big">
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                                        Social Media Marketing
                                      </li>
                                      <li class="c-unordered__list-item text-big">
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                                        Content Marketing
                                      </li>
                                      <li class="c-unordered__list-item text-big">
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                                        Email Marketing
                                      </li>
                                      <li class="c-unordered__list-item text-big">
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                                        Affiliate Marketing
                                      </li>
                                      <li class="c-unordered__list-item text-big">
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                                        Influencer Marketing
                                      </li>
                                      <li class="c-unordered__list-item text-big">
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                                        Online Public Relations (PR)
                                      </li>
                                      <li class="c-unordered__list-item text-big">
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                                        Web Design and Development
                                      </li>
                                      <li class="c-unordered__list-item text-big">
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                                        Conversion Rate Optimization (CRO)
                                      </li>
                                    </ul>
            
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          </div>
        </section>
        {/* <!-- hiring-section end --> */}
      </div>
    </>
  );
};

export default Welcome;
