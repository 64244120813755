import React from "react";
import { Link } from "react-router-dom";

const Products = () => {
  return (
    <>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-120px" }}>
                  <div
                    class="col-lg-6 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white", fontWeight: "300" }}>
                            Discover Innovative Apps for Your Daily Needs
                          </h2>
                          <p style={{ color: "black" }}>
                            Explore our wide range of mobile apps designed to
                            simplify your life. Our focus is on developing
                            real-world applications with high industry impact.
                          </p>{" "}
                          <br />
                          <div className="row ml-1 pb-2">
                            <Link to="/get-started" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Get Started</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/project/project-1.jpg"></img>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      <section className="" style={{ marginTop: "-80px" }}>
        <div className="">
          <div className="sec-title centred mt-5 pt-5 ">
            <span className="top-title">Our Product Portfolio</span>
            <h2>
              Explore High-Impact Apps Designed for Everyday Needs
            </h2>
          </div>
        </div>
      </section>

      <div className="innerContent-wrap mb-5">
        <div className="container">
          <div className="class-wrap">
            <ul className="row unorderList">
              <li className="col-lg-3 col-md-6">
                <div className="class_box shadow">
                  <div className="class_Img">
                    <img
                      src="assets/images/project/mobile-development.png"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">Mobile Development</a>
                    </h4>
                    <p>
                      Develop high-performance, feature-rich mobile applications
                      for both iOS and Android platforms with cutting-edge technology.
                    </p>
                  </div>
                  {/* <div className="row ml-1 pb-2">
                    <Link to="/mobile-development-details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/mobile-development-details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div> */}
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box shadow">
                  <div className="class_Img">
                    <img
                      src="assets/images/project/service-app.png"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">Home Service App</a>
                    </h4>
                    <p>
                      Connect with skilled professionals offering home services 
                      for repairs, cleaning, and other needs right at your doorstep.
                    </p>
                  </div>
                  {/* <div className="row ml-1 pb-2">
                    <Link to="/home-service-app-details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/home-service-app-details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div> */}
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box shadow">
                  <div className="class_Img">
                    <img
                      src="assets/images/project/doctor-appointment-app.png"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">Doctor Appointment App</a>
                    </h4>
                    <p>
                      Book medical consultations with doctors online, ensuring ease and convenience.
                    </p>
                  </div>
                  {/* <div className="row ml-1 pb-2">
                    <Link to="/doctor-appointment-app-details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/doctor-appointment-app-details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div> */}
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box shadow">
                  <div className="class_Img">
                    <img
                      src="assets/images/project/food-delivery-app.png"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">Food Delivery App</a>
                    </h4>
                    <p>
                      Get your favourite meals delivered straight to your doorstep with our easy-to-use app.
                    </p>
                  </div>
                  {/* <div className="row ml-1 pb-2">
                    <Link to="/food-delivery-app-details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/food-delivery-app-details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div> */}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
