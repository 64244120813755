import React from "react";
import Sidebar from "../Sidebar";
import { Outlet } from "react-router-dom";

const Home = () => {
  return (
    <div className="container-fluid my-5">
      <div className="row no-gutters"> {/* Added no-gutters to remove padding between columns */}
        <div className="col-md-2">
          <Sidebar />
        </div>
        <div className="col-md-10">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Home;
