import React from "react";
import Slider from "./Slider/Slider";
import Clients from "./Clients/Clients";
import Welcome from "./Welcome/Welcome";
import About from "./About/About";
import Service from "./Service/Service";
import Recruitment from "./Recruitment/Recruitment";
import Industries from "./Industries/Industries";
import Process from "./Process/Process";
import Team from "./Team/Team";
import Testimonial from "./Testimonial/Testimonial";
import Training from "./Training/Training";
import VLSICerificationTraining from "./VLSI certification program/VLSICerificationTraining";

function Blog() {
  return (
    <>
      <Slider />
      <Welcome />
     
      {/* <Service /> */}
      {/* <About /> */}
      <Training />
      {/* <VLSICerificationTraining/> */}
      <Clients />
    
      <Industries />
      <Process />
      {/* <Team /> */}
      {/* <Testimonial /> */}
    </>
  );
}

export default Blog;
