import React from "react";
import { Link } from "react-router-dom";

const CLanguagedetails = () => {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-150px" }}>
                  <div
                    class="col-lg-7 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white", fontWeight: "300" }}>
                            Full Stack Developer - MERN Stack
                          </h2>
                          <p style={{ color: "white", fontWeight: "200" }}>
                            In today’s tech landscape, full-stack developers are
                            in high demand. As organizations increasingly
                            prioritize seamless user experiences, efficient data
                            management, and versatile application deployment,
                            the need for professionals with a comprehensive
                            skill set to handle both front-end and back-end
                            development has intensified.
                          </p>{" "}
                          <br />
                          <div className="row ml-1 pb-2">
                            <Link to="" className="class_link">
                              <div className="viewmore p-4 m-1 ">
                                <a href="">View More</a>
                              </div>
                            </Link>
                            <Link to="" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Talk to Our Advisor</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/courses/mern-course.png"></img>
                      
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- React Course -details --> */}
      <section class="job-details">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-8 col-md-12 col-sm-12 content-side">
              <div class="job-details-content">
                <div class="text">
                  <h2>Course Description</h2>
                  <p>
                    This Full Stack Developer - MERN Stack program, empowers you
                    with the technical prowess demanded by this dynamic field.
                    Through immersive training and hands-on projects, you’ll
                    master both front-end and back-end technologies. With a
                    dedicated focus on MERN stack, which will help you enhance
                    your versatility and enable you to develop ecient, scalable
                    applications our curriculum encompasses a spectrum of topics
                    such as dynamic front-end design, RESTful APIs, server-side
                    logic, database management, and more, ensuring your
                    readiness for contemporary web development.
                  </p>
                  <p>
                    Engage in hands-on practice through integrated labs, live
                    virtual sessions led by industry experts, and collaborative
                    peer interactions. As a full-stack developer, you’ll be
                    equipped to meet industry demands heads on and drive
                    innovation in the ever-evolving digital realm
                  </p>
                 
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div class="job-sidebar">
                <div
                  class=" form-column"
                  style={{
                    boxShadow: "0px 0px 1px 1px lightblue",
                    borderRadius: "10px",
                    borderTop: "10px solid #662d91",
                    paddingBottom: "3px",
                  }}
                >
                  <div class="form-inner" style={{ margin: "20px" }}>
                    <form
                      // method="post"
                      // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                      id="contact-form"
                      class="default-form"
                    >
                      <div class=" clearfix">
                        <h4 style={{ textAlign: "center" }}>
                          Free Career Counselling
                        </h4>
                        <p style={{ textAlign: "center" }}>
                          We are happy to help you 24/7
                        </p>

                        <div class=" form-group mt-3">
                          <input
                            type="text"
                            // name="username"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>

                        <div class=" form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Phone *"
                          />
                        </div>
                        <div class=" form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Email Id *"
                          />
                        </div>
                        
                        <div class=" form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Enroll Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

               
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 column">
              <div class="related-job">
                <h2 style={{ textAlign: "center" }}>
                  Why MERN Full Stack Training from Ideants
                </h2>
              </div>
            </div>
            <section
              class="chooseus-section alternet-2 sec-pad-2"
              style={{ marginTop: "-130px" }}
            >
              <div class="auto-container">
                <div class="row clearfix">
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-44.png" alt=""></img></figure> */}
                        <h3>Live Interactive Learning</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> World-Class
                            Instructors
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Expert-Led
                            Mentoring Sessions
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Instant doubt
                            clearing
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="200ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-45.png" alt=""></img></figure> */}
                        <h3>Lifetime Access</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Course Access
                            Never Expires
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Free Access to
                            Future Updates
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Unlimited
                            Access to Course Content
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="400ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-46.png" alt=""></img></figure> */}
                        <h3>24x7 Live & desk Support.</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> One-On-One
                            Learning Assistance
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Help Desk
                            Support
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Resolve Doubts
                            in Real-time
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </section>
            <section
              class="chooseus-section alternet-2 sec-pad-2"
              style={{ marginTop: "-240px" }}
            >
              <div class="auto-container">
                <div class="row clearfix">
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-44.png" alt=""></img></figure> */}
                        <h3>Hands-On Project Based Learning</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i>{" "}
                            Industry-Relevant Projects
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Course Demo
                            Dataset & Files
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Quizzes &
                            Assignments
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="200ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-45.png" alt=""></img></figure> */}
                        <h3>Industry Recognised Certification</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Ideants
                            Training Certificate
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Graded
                            Performance Certificate
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Certificate of
                            Completion
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="400ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-46.png" alt=""></img></figure> */}
                        <h3>Opportunity in Top MNCs</h3>
                        <li>
                          <i class="flaticon-right-arrow"></i> Frontend
                          Developers
                        </li>
                        <li>
                          <i class="flaticon-right-arrow"></i> Web Developers
                        </li>
                        <li>
                          <i class="flaticon-right-arrow"></i> Full-stack
                          Developer
                        </li>
                        <br></br>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </section>
            <div
              class="col-lg-12 col-md-12 col-sm-12 column"
              style={{ marginTop: "-50px" }}
            >
              <div class="related-job">
                <h2 style={{ textAlign: "center" }}>
                  Instructor-led MERN Full Stack live online Training Schedule &
                  Flexible batches for you
                </h2>
              </div>
            </div>
            <section
              class="about-section sec-pad"
              style={{ marginTop: "-100px" }}
            >
              <div class="pattern-layer"></div>
              <div class="auto-container">
                <div class="inner-container">
                  <div class="row clearfix">
                    <div
                      class="col-lg-4 col-md-12 col-sm-12 content-column"
                      style={{
                        lineHeight: "150%",
                        backgroundColor: "#fdf9f3",
                        borderRight: "3px solid #F8EEDF",
                      }}
                    >
                      <div
                        id="content_block_2"
                        style={{ padding: "30px", textAlign: "center" }}
                      >
                        <h4>
                          Price: ₹
                          <span style={{ textDecoration: "line-through" }}>
                            29,999
                          </span>
                        </h4>
                        <h1>₹24,999</h1>
                        <p>17% OFF , Save ₹5000.</p>
                        <br></br>
                        <h2>Duration: 45 Days</h2>
                        <h4>Aprill 22 - Orientation Class</h4>
                        <br></br>
                        <div class=" form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Enroll Now
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-12 col-sm-12 content-column">
                      <div id="content_block_3">
                        <div class="content-box">
                          <div class="sec-title">
                            <h3 style={{ fontSize: "42px" }}>
                              MERN Full Stack Certification Training Course
                            </h3>
                            <hr />
                          </div>
                          <div
                            class="container-fluid"
                            style={{ width: "100%" }}
                          >
                            <div class="row">
                              <div class="col-md-6">
                                <ul>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Full
                                    Stack Development
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Build a
                                    Strong MERN Foundation
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Design
                                    a Dynamic Frontend with React
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Data
                                    Structures and Algorithms
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Develop
                                    a Reliable Backend with Node and Express
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              class="about-section sec-pad"
              style={{ marginTop: "-190px", width: "100%" }}
            >
              <div class="pattern-layer"></div>
              <div class="auto-container">
                <div class="inner-container">
                  <div class="row clearfix">
                    <div
                      class="col-lg-6 col-md-12 col-sm-12 content-column"
                      style={{ borderRight: "2px solid lightgrey" }}
                    >
                      <div id="content_block_2" style={{ padding: "30px" }}>
                        <h3 style={{ textAlign: "center", fontSize: "36px" }}>
                          Skills Covered
                        </h3>
                        <hr></hr>

                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Planning
                            projects with Agile
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Version control
                            system: Git
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> HTML/CSS
                          </li>
                          <li>
                            {" "}
                            <i class="flaticon-right-arrow"></i> Javascript
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Bootstrap
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                      <div id="content_block_3">
                        <div class="content-box">
                          <div class="sec-title">
                            <h3
                              style={{ textAlign: "center", fontSize: "36px" }}
                            >
                              Tools Covered
                            </h3>
                            <hr></hr>
                            <img src="assets/images/courses/react.png"></img>
                            <img src="assets/images/courses/redux.png"></img>
                            <img src="assets/images/courses/graphql.png"></img>
                            <img src="assets/images/courses/javascript.png"></img>
                            <img src="assets/images/courses/jest.png"></img>
                            <img src="assets/images/courses/git.png"></img>
                            <img src="assets/images/courses/docker.png"></img>
                            <img src="assets/images/courses/nginx.png"></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div class=" form-group message-btn centred">
                    <button
                      class="theme-btn-one"
                      type="submit"
                      // name="submit-form"
                    >
                      Short term Internship Program Details
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class=" form-group message-btn centred">
                    <button
                      class="theme-btn-one"
                      type="submit"
                      // name="submit-form"
                    >
                      Long term Internship Program Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <section
              class="faq-section text-left"
              style={{ width: "100%", marginTop: "-20px" }}
            >
              <div class="container-fluid text-left" style={{ width: "100%" }}>
                <div class="row">
                  <div class="col-md-8">
                    <section
                      class="btc-accordion text-left"
                      style={{ width: "100%" }}
                    >
                      <div
                        class="container text-left"
                        style={{ width: "100%" }}
                      >
                        <h1 class="text-center text-black">
                          MERN Full Stack Certification Training Course
                          Curriculum
                        </h1>
                        <br />
                        <p class="text-center text-black">
                          <h4>Curriculum Designed by Experts</h4>{" "}
                        </p>

                        <div class="row">
                          <div class="col-md-12">
                            <div class="accordion mb-2" id="accordionExample">
                              <div class="card">
                                <div
                                  class="card-header collapsed"
                                  id="headingOne"
                                >
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                  >
                                    <h5 class="py-2">
                                      {" "}
                                      1. Induction Full-stack Development - MERN
                                      Program (3 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseOne"
                                  class="collapse"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Get acquainted with your peers
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Get a complete understanding of the
                                            program
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Map your career goals to the
                                            certificate program experience
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Installation of Node.js, React and
                                              Visual Studio Code
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Creating First React Application
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Working with DOM
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Implementing NPM Modules
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingTwo">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    <h5 class="py-2">
                                      {" "}
                                      2. Build a strong MERN Foundation(5
                                      Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseTwo"
                                  class="collapse"
                                  aria-labelledby="headingTwo"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Planning projects with Agile
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>
                                            Version control system: Git
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            HTML/CSS
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Javascript
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Bootstrap
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Become proficient at planning and
                                              managing projects using Agile
                                              methodologies
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Creating React Components
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Working with React Forms
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Styling the React Components
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingThree">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    <h5 class="py-2">
                                      3. Design a Dynamic Frontend with React(7
                                      Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseThree"
                                  class="collapse"
                                  aria-labelledby="headingThree"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            JSON server (CRUD operations)
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            - React introduction and basic
                                            concepts
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Rendering, LifeCycle and React Hook
                                            concepts
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            ReactHook useReducer, useEffect and
                                            custom hooks management
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            React Context API & Redux
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Data retrieval using Redux
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>
                                            - API calling with React
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Master CRUD operations using JSON
                                              Servers, allowing you to
                                              efficiently create, read, update,
                                              and delete data, enhancing your
                                              ability to manage data within
                                              applications{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Seamlessly integrate APIs with
                                              React applications, enabling you
                                              to fetch and display data from
                                              external services
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Establish a strong foundation in
                                              React, comprehending its core
                                              concepts, enabling you to create
                                              and manage dynamic user interfaces
                                              efficiently
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Working with REST API
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Navigation among various
                                              components
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingFour">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                  >
                                    <h5 class="py-2">
                                      4. Data Structures and Algorithms(10
                                      Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFour"
                                  class="collapse"
                                  aria-labelledby="headingFour"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to data structures
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Arrays and linked lists
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>
                                            Stacks and queues
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>
                                            : Hashing and hash tables
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>
                                            : Trees, heaps and priority queues
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Graphs
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Searching and sorting algorithms
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            8: Recursion and backtracking
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            : Greedy algorithms
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Graph and string algorithms
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Gain a solid understanding of
                                              fundamental data structures,
                                              including arrays, linked lists,
                                              stacks, queues, trees, heaps, and
                                              graphs, enabling you to select the
                                              appropriate structure for various
                                              scenarios
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Develop proficiency in solving
                                              problems using various searching,
                                              sorting, and string manipulation
                                              algorithms, equipping you with
                                              essential problem-solving skills
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Managing and updating application
                                              state
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Working with NPM packages
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingFive">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                  >
                                    <h5 class="py-2">
                                      5. Develop a Reliable Backend with Node
                                      and Express(4 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFive"
                                  class="collapse"
                                  aria-labelledby="headingFour"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            SQL
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            MongoDB
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Node.js
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>
                                            : Express.js
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Design a logging and monitoring
                                              solution
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Become skilled in MongoDB,
                                              mastering NoSQL database concepts
                                              and efficiently handling non
                                              relational data storage,
                                              retrieval, and management
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Develop proficiency in Node.js,
                                              enabling you to build scalable,
                                              server-side applications, handle
                                              asynchronous operations, and
                                              optimize performance
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Working with middleware for Redux
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Building and Debugging application
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="col-md-4">
                    <div
                      class=" form-column"
                      style={{
                        boxShadow: "0px 0px 1px 1px lightblue",
                        borderRadius: "10px",
                        borderTop: "10px solid #662d91",
                        paddingBottom: "3px",
                        width: "100%",
                        marginTop: "155px",
                      }}
                    >
                      <div class="form-inner" style={{ margin: "20px" }}>
                        <form
                          // method="post"
                          // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                          id="contact-form"
                          class="default-form"
                        >
                          <div class=" clearfix">
                            <h4 style={{ textAlign: "center" }}>
                              Free Career Counselling
                            </h4>
                            <p style={{ textAlign: "center" }}>
                              We are happy to help you 24/7
                            </p>

                            <div class=" form-group mt-3">
                              <input
                                type="text"
                                // name="username"
                                placeholder="Your Name *"
                                required=""
                              />
                            </div>

                            <div class=" form-group">
                              <input
                                type="text"
                                // name="phone"
                                required=""
                                placeholder="Phone *"
                              />
                            </div>
                            <div class=" form-group">
                              <input
                                type="text"
                                // name="phone"
                                required=""
                                placeholder="Email Id *"
                              />
                            </div>
                           
                            <div class=" form-group message-btn centred">
                              <button
                                class="theme-btn-one"
                                type="submit"
                                // name="submit-form"
                              >
                                Enroll Now
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            
          </div>
        </div>
      </section>
      {/* <!-- React Course-details end --> */}
    </div>
  );
};

export default CLanguagedetails;
