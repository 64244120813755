import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt, faBuilding, faUser, faBook } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="container-fluid shadow">
      <div className="p-5">
        <Link to="/home" className="d-block mb-4">
          <FontAwesomeIcon icon={faTachometerAlt} className="mr-2" /> Dashboard
        </Link>
        <Link to="employers1" className="d-block mb-4">
          <FontAwesomeIcon icon={faBuilding} className="mr-2" /> Employers
        </Link>
        <Link to="job-seekers1" className="d-block mb-4">
          <FontAwesomeIcon icon={faUser} className="mr-2" /> Job Seekers
        </Link>
        <Link to="courses1" className="d-block">
          <FontAwesomeIcon icon={faBook} className="mr-2" /> Courses
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
