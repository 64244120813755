import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel2";
import { Link } from "react-router-dom";


const Welcome = () => {
  useEffect(() => {
    // Add any necessary initialization code here
  }, []);
  return (
    <>
      <div>
        {/* <!--Page Title--> */}
        <section
          class="page-title"
          style={{
            backgroundImage: "url(assets/images/background/page-title-2.jpg)",
          }}
        >
          <div
            class="pattern-layer"
            style={{
              backgroundImage: "url(assets/images/shape/pattern-35.png)",
            }}
          ></div>
          <div class="auto-container">
            <div class="content-box">
              {/* <div class="title-box centred">
                <h1>Employers</h1>
                <p>Your Staffing Needs are Unique and Always Evolving</p>
              </div> */}

              {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-120px" }}>
                  <div
                    class="col-lg-6 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white",fontWeight:"300" }}>Are you looking to hire for your company?</h2>
                          <p style={{ color: "black" }}>
                          Finding the right candidate for your business is a crucial decision that hinges 
                          on the way you search for, select and interview potential applicants. Our specially
                           designed solutions bring you talent quickly. us on info@zeuscrew.in to join the
                            conversation.
                          </p>{" "}<br></br>
                          <div className="row ml-1 pb-2">
                          <Link
                              to=""
                              className="class_link"
                            >
                              <div className="booknow p-4 m-1 ">
                                <a href="">Post a Job</a>
                              </div>
                            </Link>
                            <Link
                              to=" "
                              className="class_link"
                            >
                              <div className="booknow p-4 m-1 ">
                                <a href="">Request For Tallent</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        // boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/banner/employers.jpeg"></img>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
              
            </div>
          </div>
        </section>
        {/* <!--End Page Title--> */}
        
        <section class="hiring-strategies">
            <div class="auto-container">
                <div class="sec-title centred">
                    <span class="top-title">Welcome to Zeus Crew Services</span>
                    <h2>Improve Your Hiring Strategies</h2>
                    <p>Effortlessly discover top talent with Zeus Crew Services. Your premier destination for hiring your next standout candidate. Experience unmatched efficiency and precision in your hiring process with Zeus Crew Services by your side.</p>
                </div>
                <div class="inner-box">
                    <div class="title-inner bg-color-2">
                        <div class="pattern-layer" style={{ backgroundImage:
                "url('assets/images/shape/pattern-40.png')"}} ></div>
                        <h2>Are You Looking for an Employee?...</h2>
                        <a href="#" class="theme-btn-two">Hire Now</a>
                    </div>
                    <div class="funfact-inner clearfix">
                        <div class="row clearfix">
                            <div class="col-lg-4 col-md-6 col-sm-12 counter-column">
                                <div class="counter-block">
                                    <div class="count-outer count-box">
                                        <span>0</span><span class="count-text" data-speed="1500" data-stop="8">5</span><span class="icon flaticon-up-arrow-2"></span>
                                    </div>
                                    <h6>Years of Experience in Field</h6>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 counter-column">
                                <div class="counter-block">
                                    <div class="count-outer count-box">
                                        <span class="count-text" data-speed="1500" data-stop="125">100</span>
                                    </div>
                                    <h6>Companies With Our Tie-up</h6>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 counter-column">
                                <div class="counter-block">
                                    <div class="count-outer count-box">
                                        <span class="count-text" data-speed="1500" data-stop="6.2">2</span><span>k</span>
                                    </div>
                                    <h6>recruitments <br />for our partners</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        {/* <!-- solutions-problems --> */}

        <section className="solutions-problems mt-5">
          <div className="auto-container">
            <div className="upper-box">
              <div className="row clearfix">
                <div className="col-lg-6 col-md-12 col-sm-12 title-column">
                  <div className="title-inner">
                    <h2>
                      Realistic & <br />
                      Common sense <br />
                      Solutions to HR Problems
                    </h2>
                    <p>
                    Practical and commonsense HR solutions tailored to address your challenges effectively and efficiently.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 inner-column">
                  <div className="inner-box">
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-6 col-sm-12 column">
                        <div className="single-item">
                          <figure className="icon-box">
                            <img src="assets/images/icons/icon-54.png" alt="" />
                          </figure>
                          <h3>
                            Understand Your <br />
                            Needs
                          </h3>
                          <p>
                          We prioritize understanding your needs to deliver tailored solutions that exceed expectations.
                          </p>
                          <a href="">
                            <i className="flaticon-right-arrow"></i>Start
                            Discuss
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 column">
                        <div className="single-item">
                          <figure className="icon-box">
                            <img src="assets/images/icons/icon-55.png" alt="" />
                          </figure>
                          <h3>Find the Perfect Candidate</h3>
                          <p>
                          Let us find the perfect candidate who aligns seamlessly with your requirements and company culture.
                          </p>
                          <a href="">
                            <i className="flaticon-right-arrow"></i>Post a Job
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-box">
              <h2 style={{textAlign:"center",fontSize:"42px"}}>That's how easy it is to request staff from Ideants</h2>
              <OwlCarousel
                className="two-column-carousel owl-carousel owl-theme owl-dot-style-two owl-nav-none"
                items={2}
                loop
                autoplay
                nav
                dots
                margin={10} // Adjust the margin as needed
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 2,
                  },
                  1000: {
                    items: 2,
                  },
                }}
              >
                <div className="single-item">
                  <div className="inner-box">
                    <figure className="image-box">
                      <img
                        src="assets/images/resource/solutions-1.jpg"
                        alt=""
                      />
                    </figure>
                    <div className="lower-content">
                      <h3>
                        Signup With Ideants and Fill the form & get connect.
                      </h3>
                    </div>
                    <div className="side-content">
                      <div className="content-box">
                        <span>01.</span>
                        <figure className="icon-box">
                          <img src="assets/images/icons/icon-56.png" alt="" />
                        </figure>
                      </div>
                      <div className="overlay-box">
                        <span>01.</span>
                        <a href="">
                          <i className="flaticon-right-arrow-angle"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="single-item">
                  <div className="inner-box">
                    <figure className="image-box">
                      <img
                        src="assets/images/resource/solutions-1.jpg"
                        alt=""
                      />
                    </figure>
                    <div className="lower-content">
                      <h3>
                        Candidate Search & Filter with suitable requirements.
                      </h3>
                    </div>
                    <div className="side-content">
                      <div className="content-box">
                        <span>02.</span>
                        <figure className="icon-box">
                          <img src="assets/images/icons/icon-56.png" alt="" />
                        </figure>
                      </div>
                      <div className="overlay-box">
                        <span>02.</span>
                        <a href="">
                          <i className="flaticon-right-arrow-angle"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="single-item">
                  <div className="inner-box">
                    <figure className="image-box">
                      <img
                        src="assets/images/resource/solutions-1.jpg"
                        alt=""
                      />
                    </figure>
                    <div className="lower-content">
                      <h3>
                        Perfect Match, Recruite, Engage, Retain.
                      </h3>
                    </div>
                    <div className="side-content">
                      <div className="content-box">
                        <span>03.</span>
                        <figure className="icon-box">
                          <img src="assets/images/icons/icon-56.png" alt="" />
                        </figure>
                      </div>
                      <div className="overlay-box">
                        <span>03.</span>
                        <a href="">
                          <i className="flaticon-right-arrow-angle"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </section>

        {/* <!-- solutions-problems end --> */}

        {/* <!-- findjob-section --> */}
        <section class="findjob-section" style={{marginTop:"-150px"}}>
          <div class="auto-container">
            <div class="sec-title centred">
              <span class="top-title">Our Employees</span>
              <h2>Find Employees based on Technologies</h2>
              <p>
              Discover top talent proficient in specific technologies tailored to your unique business needs.
              </p>
            </div>
            <div class="search-inner">
              <form
                // action=""
                // method="post"
                class="search-form"
              >
                <div class="row clearfix">
                  <div class="col-lg-4 col-md-12 col-sm-12 column">
                    <div class="form-group">
                      <i class="flaticon-search"></i>
                      <input
                        type="search"
                        // name="search-field"
                        placeholder="Keyword (Technology)"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12 column">
                    <div class="form-group">
                      <i class="flaticon-place"></i>
                      <input
                        type="search"
                        // name="search-field"
                        placeholder="Location (City or State)"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12 column">
                    <div class="form-group">
                      <i class="flaticon-clock"></i>
                      <input
                        type="search"
                        // name="search-field"
                        placeholder="Enter Experience"
                        required=""
                      />
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12 column">
                    <div class="form-group message-btn">
                      <button type="submit" class="theme-btn-one">
                        Search CAndidates
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="post-jobs">
              <div class="single-job-post">
                <div
                  class="job-inner clearfix"
                  style={{
                    borderTop: "10px solid #462b34 ",
                  }}
                >
                  <div class="job-title">
                    <figure class="company-logo">
                      <img src="assets/images/resource/logo-1.png" alt="" />
                    </figure>
                    <h3>REACT Developer</h3>
                    <p>
                      <i class="flaticon-place"></i>Bangalore, Karnataka
                    </p>
                  </div>
                  <div class="salary-box">
                    <span>Current CTC</span>
                    <p>₹6,00,000 Per Year</p>
                  </div>
                  <div class="experience-box">
                    <span>Experience </span>
                    <p>2+ Yrs</p>
                  </div>
                  <div class="apply-btn">
                    <a href="">View More</a>
                  </div>
                </div>
              </div>
              <div class="single-job-post">
                <div
                  class="job-inner clearfix"
                  style={{
                    borderTop: "10px solid #462b34 ",
                  }}
                >
                  <div class="job-title">
                    <figure class="company-logo">
                      <img src="assets/images/resource/logo-2.png" alt="" />
                    </figure>
                    <h3>UX Designer</h3>
                    <p>
                      <i class="flaticon-place"></i>Pune, Maharastra
                    </p>
                  </div>
                  <div class="salary-box">
                    <span>Current CTC</span>
                    <p>₹ 7,50,000 Per Year</p>
                  </div>
                  <div class="experience-box">
                    <span>Experience </span>
                    <p>3+ Yrs</p>
                  </div>
                  <div class="apply-btn">
                    <a href="">View more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- findjob-section end --> */}
      </div>
    </>
  );
};

export default Welcome;
