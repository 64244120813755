import React, { useState } from "react";
import { Link } from "react-router-dom";

const Register = () => {
  const [userType, setUserType] = useState(""); // State to manage selected user type

  // Function to handle checkbox selection
  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };
  return (
    <div>
      <section class="about-section sec-pad">
        <div
          class="pattern-layer"
          style={{ backgroundImage: "url('assets/images/shape/pattern-5.png)" }}
        ></div>
        <div class="auto-container">
          <div class="inner-container">
            <div class="row clearfix">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div id="content_block_2">
                  <div
                    class="content-box centred"
                    style={{ backgroundColor: "#f0e6e0", padding: "50px" }}
                  >
                    <div class="title-inner">
                      <h2 style={{ textAlign: "center" }}>Get Started Now</h2>
                      <p style={{ textAlign: "center" }}>
                        Partner for talent. Your specialised and equitable work
                        partner.
                      </p>
                    </div>
                    <br></br>
                    <form id="contact-form" class="default-form form-group">
                      <div class=" clearfix">
                        <div
                          class="col-lg-12 col-md-6 col-sm-6 form-group"
                          style={{ textAlign: "center", fontSize: "20px" }}
                        >
                          <input
                            type="radio"
                            placeholder="Email Address"
                            value="employer"
                            checked={userType === "employer"}
                            onChange={handleUserTypeChange}
                            required=""
                          />{" "}
                          An Employer
                          <input
                            style={{ marginLeft: "50px" }}
                            type="radio"
                            placeholder="Email Address"
                            value="jobSeeker"
                            checked={userType === "jobSeeker"}
                            onChange={handleUserTypeChange}
                            required=""
                          />{" "}
                          A Job Seeker
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="email"
                            placeholder="Email Address"
                            required=""
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            required=""
                            placeholder="Password "
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            required=""
                            placeholder="Confirm Password "
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                          <button class="theme-btn-one" type="submit">
                            SignUp
                          </button>
                          <br></br>
                          <h6 style={{ float: "left" }}>
                            Already have an account?{" "}
                            <a href="/login" style={{ color: "purple" }}>
                              Login
                            </a>
                          </h6>
                        </div>
                      </div>
                    </form>
                    <br></br>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 content-column" style={{padding:"0px"}}>
                <br></br>
                <div id="content_block_3">
                  <div class="content-box">
                    <div class="title-inner">
                      <h2 style={{ textAlign: "center" }}>
                        Partner for talent.
                      </h2>
                      <p style={{ textAlign: "center" }}>
                        Your specialised and equitable work partner
                      </p>
                    </div>
                    <br></br>
                    <form id="contact-form" class="default-form form-group">
                      <div class=" clearfix">
                        <img
                          src="assets/images/clients/google.png"
                          alt=""
                          style={{ width: "100px", margin: "10px" }}
                        />
                        <img
                          src="assets/images/clients/microsoft.png"
                          alt=""
                          style={{ width: "100px", margin: "20px" }}
                        />
                        <img
                          src="assets/images/clients/tcs.png"
                          alt=""
                          style={{ width: "100px", margin: "20px" }}
                        />
                        <img
                          src="assets/images/clients/wipro.png"
                          alt=""
                          style={{ width: "100px", margin: "20px" }}
                        />
                        <img
                          src="assets/images/clients/techmahindra.png"
                          alt=""
                          style={{ width: "100px", margin: "10px" }}
                        />
                        <img
                          src="assets/images/clients/amazon.png"
                          alt=""
                          style={{ width: "100px", margin: "20px" }}
                        />
                         <img
                          src="assets/images/clients/infosys.png"
                          alt=""
                          style={{ width: "100px", margin: "20px" }}
                        />
                         <img
                          src="assets/images/clients/hcl.png"
                          alt=""
                          style={{ width: "100px", margin: "20px" }}
                        />
                         <img
                          src="assets/images/clients/cognizant.png"
                          alt=""
                          style={{ width: "100px", margin: "10px" }}
                        />
                         <img
                          src="assets/images/clients/accenture.png"
                          alt=""
                          style={{ width: "100px", margin: "20px" }}
                        />
                         <img
                          src="assets/images/clients/ltimindtree.png"
                          alt=""
                          style={{ width: "100px", margin: "20px" }}
                        />
                         <img
                          src="assets/images/clients/oracle.png"
                          alt=""
                          style={{ width: "100px", margin: "20px" }}
                        />
                        <h5>Create your profile with easy-to fill form and get recommended with relevant partner for talent.</h5>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;
