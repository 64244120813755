import React from "react";
import { Link } from "react-router-dom";

const Contracthire = () => {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-120px" }}>
                  <div
                    class="col-lg-6 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white",fontWeight:"300" }}>Contract to Hire - everything you need to know</h2>
                          <p style={{ color: "black" }}>
                          Contract to Hire employees enter fixed-term contracts with a staffing agency before becoming hired as full-time employees at your company.
                          </p>{" "}
                          <br /> <br /> 
                          <div className="row ml-1 pb-2">
                            <Link
                              to=""
                              className="class_link"
                            >
                              <div className="viewmore p-4 m-1 ">
                                <a href="">Get Hired</a>
                              </div>
                            </Link>
                            <Link
                              to=""
                              className="class_link"
                            >
                              <div className="booknow p-4 m-1 ">
                                <a href="">Talk to Our Advisor</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/banner/contract-hire.jpg"></img>
                      
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
            
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- service-details --> */}
      <section class="service-details">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-8 col-md-12 col-sm-12 content-side">
              <div class="service-details-content">
                <div class="content-one">
                  <div class="sec-title">
                    <h2>Contract To Hire Staffing</h2>
                  </div>
                  <figure class="image-box">
                    <img src="assets/images/banner/contract-hire-1.jpg" alt="" />
                  </figure>
                  <div class="text">
                    <p>
                    By utilizing Ideants contract-to-hire staffing services, a company can benefit from the traditional services a temporary 
                    staffing agency offers with the option to hire the employee permanently. You can observe the employee on the job t
                    o determine if he or she is the right fit for the position. After the contract period is complete, the employee can 
                    be hired on a permanent basis.
                    </p>
                    
                  </div>
                </div>
                
                <div class="content-four">
                  <div class="upper-box">
                    <h3>Contract-to-hire advantages</h3>
                    <p>
                    Not all businesses need contract-to-hire positions. Without a doubt, 
                    this model has many advantages, but the additional costs aren’t always worth it.
                     Companies that recruit highly paid specialists utilize this model to assess employees 
                     before committing to them, but it doesn’t make sense for positions that are lower in cost. That said, 
                     let’s explore the major advantages of a contract-to-hire approach.
                    </p>
                  </div>
                  <div class="inner-box">
                    <div class="row clearfix">
                      <div class="col-lg-6 col-md-6 col-sm-12 column">
                        <ul class="list-item clearfix">
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-short-tie"></i>
                            </div>
                            <h5>Easier hiring process</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Your recruitment team doesn’t have to take care of the whole hiring process. Working with an agency means 
                                they will do the legwork for you. All you have to do is send the requirements, budget,
                                 and expectations to your partner, and they will come to you with pre-screened candidates
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-money-bag"></i>
                            </div>
                            <h5>Trial period</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                For many companies, this is the most significant benefit of a contract-to-hire model. Both sides
                                 can put to the test if they are a fit befor they fully commit. During the contract period, 
                                 for example a 3-month contract-to-hire arrangement, 
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-google-maps"></i>
                            </div>
                            <h5>You get more flexibility</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                When you hire full-time, it’s difficult to change the employment contract, and the employee has more bargaining power. In a contract-to-hire agreement, you get to try out the employee before making a decision to hire them.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 column">
                        <ul class="list-item clearfix">
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-hourglass"></i>
                            </div>
                            <h5>Faster process</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                The hiring agencies have vast databases of candidates, including those with niche skills. 
                                They also know who is on a job search and who doesn’t want to look for a new position. 
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-lightbulb"></i>
                            </div>
                            <h5>Savings and budget options</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                If your workforce budget is restrained, with contract-to-hire arrangements, you get an equivalent of a full-time employee by utilizing the CapEx budget.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-google-maps"></i>
                            </div>
                            <h5> Savings on specialized skills</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Niche technology skills are hard to find, especially when you need senior talent. The problem grows when such skills are needed for a relatively short time. Finding a full-time employee will be time-consuming and expensive, 
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div class="service-sidebar">
                <div class="sidebar-widget categories-widget">
                  <ul class="categories-list clearfix">
                    <li>
                      <a href="temporary-staffing">Temprory Staffing</a>
                    </li>
                    <li>
                      <a href="direct-hire">Direct Hire</a>
                    </li>
                    <li>
                      <a href="contract-hire" class="active">
                        Contract to Hire
                      </a>
                    </li>
                    <li>
                      <a href="payrol-hire">Payrolling</a>
                    </li>
                    <li>
                      <a href="carporate-training">Corporate Training</a>
                    </li>
                    <li>
                      <a href="executive-search">Executive Search</a>
                    </li>
                  </ul>
                </div>
                <div class="sidebar-widget recruitment-widget">
                  <div class="widget-content">
                    <div class="text">
                      <div
                        class="pattern-layer"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-43.png)",
                        }}
                      ></div>
                      <figure class="image-box">
                        <img src="assets/images/resource/men-1.png" alt="" />
                      </figure>
                      <h3>Recruitment Solutions for All Industries...</h3>
                    </div>
                    <div class="link">
                      <a href="">
                        <i class="flaticon-right-arrow"></i>Place a Job Order
                      </a>
                    </div>
                  </div>
                </div>
                <div class="sidebar-widget subscribe-widget">
                  <h3>Reach Out</h3>
                  <form
                    // action=""
                    // method="post"
                    class="subscribe-form"
                  >
                    <div class="form-group">
                      <label>Your Name</label>
                      <input
                        type="text"
                        // name="name"
                        placeholder="Mr/Mrs.xxxxx"
                        required=""
                      />
                    </div>
                    <div class="form-group">
                      <label>Email Address</label>
                      <input
                        type="email"
                        // name="name"
                        placeholder="example@domain.com"
                        required=""
                      />
                    </div>
                    
                    <div class="form-group message-btn">
                      <button type="submit" class="theme-btn-one">
                        Send Your Interest
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- service-details end --> */}

    </div>
  );
};

export default Contracthire;
