import React from "react";
import { Link } from "react-router-dom";
import  { useState } from "react";
import Clients from "../../../Clients/Clients";

const LongtermInternship = () => {
  const [activeTab, setActiveTab] = useState("#tab-3");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-120px" }}>
                  <div
                    class="col-lg-7 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white", fontWeight: "300" }}>
                          Master Full Stack Development & Long Term Internship
                          </h2>
                          <p style={{ color: "white" }}>
                          Long internships up to Six Months. Completing a
long term internship shows dedication, a great
work ethic, and a willingness to learn. While
doing a long term internship may seem daunting,
it looks fantastic on a experience and can open
many doors for you.

                          </p>{" "}
                          <br />
                          <div className="row ml-1 pb-2">
                            <Link to="/our-courses" className="class_link">
                              <div className="viewmore p-4 m-1 ">
                                <a href="">View Courses</a>
                              </div>
                            </Link>
                            <Link to="/job-seekers" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Get Hired</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-12 col-sm-12 content-column ">
                    <div>
                      <div class="ml-5">
                        <div class="job-sidebar">
                          <div
                            class=" form-column"
                            style={{
                              
                            }}
                          >
                            <div class="form-inner" style={{ boxShadow: "0px 0px 1px 1px lightblue",
                              borderRadius: "15px",
                              borderTop: "15px solid #662d91",
                              paddingBottom: "13px",
                              backgroundColor: "white",
                              paddingLeft:"25px",
                              paddingRight:"25px" }}>
                              <form
                                // method="post"
                                // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                                id="contact-form"
                                class="default-form"
                              >
                                <div class=" clearfix">
                                  <h4 style={{ textAlign: "center" }}>
                                  Book a free Demo
                                  </h4>
                                  <p style={{ textAlign: "center" }}>
                                    {/* We are happy to help you 24/7 */}
                                  </p>

                                  <div class=" form-group mt-3">
                                    <input
                                      type="text"
                                      // name="username"
                                      placeholder="Your Name *"
                                      required=""
                                    />
                                  </div>

                                  <div class=" form-group">
                                    <input
                                      type="text"
                                      // name="phone"
                                      required=""
                                      placeholder="Phone *"
                                    />
                                  </div>
                                  <div class=" form-group">
                                    <input
                                      type="text"
                                      // name="phone"
                                      required=""
                                      placeholder="Email Id *"
                                    />
                                  </div>

                                  <div class=" form-group message-btn centred">
                                    <button
                                      class="theme-btn-one"
                                      type="submit"
                                      // name="submit-form"
                                    >
                                      Enroll Now
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
          </div>
        </div>
      </section>
      <section class="process-section" style={{ marginTop: "-5px", backgroundColor:"#f0e6e0" }}>
        <div class="container-fluid">
          <div class="row clearfix">
            <div class="col-lg-2 col-md-6 col-sm-12 process-block">
              <div
                class="process-block-one wow fadeInUp animated animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div class="inner-box">
                  <div class="lower-content">
                    <br></br>
                    <br></br>
                    <br></br>
                    <h2 style={{ textAlign: "center" }}>
                      Graduate without Job
                    </h2>
                    <img src="assets/images/Modern-Education.png"></img>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-md-6 col-sm-12 process-block">
            <h2 style={{textAlign:"center"}}>Achieve your high paying dream career with Long Term Internship</h2> 
            <p style={{textAlign:"center", fontSize:"24px"}}>Our top notch teams involved in helping you learn programming, not just coding.</p>
              <div
                class="process-block-one wow fadeInUp animated animated"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div class="inner-box">
                  <section
                    class="chooseus-section alternet-2 sec-pad-2"
                    style={{ marginTop: "-80px" }}
                  >
                    <div class="auto-container">
                      <div class="row clearfix">
                        
                        <div class="col-lg-2 col-md-6 col-sm-12 chooseus-block">
                          <div
                            class="chooseus-block-two wow fadeInUp animated animated"
                            data-wow-delay="00ms"
                            data-wow-duration="1500ms"
                          >
                            <div
                              class="inner-box"
                              style={{
                                width: "200px",
                                marginLeft: "-30px",
                                marginTop: "300px",
                                borderTop:"10px solid purple"
                              }}
                            >
                              <h3>Learn to Code</h3>
                              {/* <p>
                              Learn to 'Think in code' and build problem-solving ability
                              </p>  */}
                            </div>
                          </div>
                          <p
                            style={{
                              backgroundColor: "purple",
                              position: "absolute",
                              bottom: "0px",
                              width: "120%",
                              height: "50px",
                              left: "-14px",
                              right: "0px",
                              margin: "0px",
                              color: "white",
                              textAlign: "center",
                              paddingTop: "10px",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            Fundamentals 
                          </p>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-12 chooseus-block">
                          <div
                            class="chooseus-block-two wow fadeInUp animated animated"
                            data-wow-delay="200ms"
                            data-wow-duration="1500ms"
                          >
                            <div
                              class="inner-box"
                              style={{
                                width: "200px",
                                marginLeft: "10px",
                                marginTop: "220px",
                                borderTop:"10px solid purple"
                              }}
                            >
                              <h3>Master Full-Stack Development</h3>
                              {/* <p>
                              Learn advanced frameworks for frontend and backend development
                              </p>  */}
                              {/* <a href="index-2.html"><i class="flaticon-right-arrow"></i>More Details</a> */}
                            </div>
                            <p
                              style={{
                                backgroundColor: "purple",
                                position: "absolute",
                                bottom: "0px",
                                width: "145%",
                                height: "50px",
                                left: "10px",
                                right: "0px",
                                margin: "0px",
                                color: "white",
                                textAlign: "center",
                                paddingTop: "10px",
                                fontSize: "20px",
                                fontWeight: "bold",
                                zIndex: "1",
                              }}
                            >
                              {" "}
                              Development
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-12 chooseus-block">
                          <div
                            class="chooseus-block-two wow fadeInUp animated animated"
                            data-wow-delay="400ms"
                            data-wow-duration="1500ms"
                          >
                            <div
                              class="inner-box"
                              style={{
                                width: "200px",
                                marginLeft: "50px",
                                marginTop: "140px",
                                borderTop:"10px solid purple"
                              }}
                            >
                              <h3>Develop Industry-grade Projects</h3>
                              {/* <p>
                              Make Your Mark in Coding Contests.
                              </p> */}
                              {/* <a href="index-2.html"><i class="flaticon-right-arrow"></i>More Details</a> */}
                            </div>
                            <p
                              style={{
                                backgroundColor: "purple",
                                position: "absolute",
                                bottom: "0px",
                                width: "145%",
                                height: "50px",
                                left: "50px",
                                right: "0px",
                                margin: "0px",
                                color: "white",
                                textAlign: "center",
                                paddingTop: "10px",
                                fontSize: "20px",
                                fontWeight: "bold",
                                zIndex: "1",
                              }}
                            >
                              {" "}
                              Client Projects
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-12 chooseus-block">
                          <div
                            class="chooseus-block-two wow fadeInUp animated animated"
                            data-wow-delay="600ms"
                            data-wow-duration="1500ms"
                          >
                            <div
                              class="inner-box"
                              style={{
                                width: "200px",
                                marginLeft: "90px",
                                marginTop: "90px",
                                borderTop:"10px solid purple"
                              }}
                            >
                              <h3>build your developer profile ike a Pro </h3>
                              {/* <p>
                                Business it will frequently pleasures
                                repudiated.
                              </p> */}
                              {/* <a href="index-2.html"><i class="flaticon-right-arrow"></i>More Details</a> */}
                            </div>
                            <p
                              style={{
                                backgroundColor: "purple",
                                position: "absolute",
                                bottom: "0px",
                                width: "145%",
                                height: "50px",
                                left: "90px",
                                right: "0px",
                                margin: "0px",
                                color: "white",
                                textAlign: "center",
                                paddingTop: "10px",
                                fontSize: "20px",
                                fontWeight: "bold",
                                zIndex: "1",
                              }}
                            >
                              {" "}
                              Experience
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-12 chooseus-block">
                          <div
                            class="chooseus-block-two wow fadeInUp animated animated"
                            data-wow-delay="600ms"
                            data-wow-duration="1500ms"
                          >
                            <div
                              class="inner-box"
                              style={{ width: "200px", marginLeft: "130px", marginTop:"40px",
                              borderTop:"10px solid purple"}}
                            >
                              <h3>Get Deploy to Client with upto 24LPA Job</h3>
                              {/* <p>
                                Business it will frequently pleasures
                                repudiated.
                              </p> */}
                              {/* <a href="index-2.html"><i class="flaticon-right-arrow"></i>More Details</a> */}
                            </div>
                            <p
                              style={{
                                backgroundColor: "purple",
                                position: "absolute",
                                bottom: "0px",
                                width: "145%",
                                height: "50px",
                                left: "131px",
                                right: "0px",
                                margin: "0px",
                                color: "white",
                                textAlign: "center",
                                paddingTop: "10px",
                                fontSize: "20px",
                                fontWeight: "bold",
                                zIndex: "1",
                              }}
                            >
                              {" "}
                              You're Hired
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-12 process-block">
              <div
                class="process-block-one wow fadeInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div class="inner-box">
                  <div class="lower-content">
                    
                    <img src="assets/images/got-job.png"></img>
                    <h2 style={{ textAlign: "center" }}>
                      Dream Job With Higher Package
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}
      <section class="history-section">
        <figure class="image-layer" style={{ marginTop: "300px" }}>
          <img src="assets/images/resource/history-1.png" alt="" />
        </figure>
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-4 col-md-12 col-sm-12 column">
              <div class="sec-title">
                <h2>Get Ready for Your IT Career in 3 Steps</h2>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 column">
              <div class="inner-box">
                <figure class="image-box">
                  <img src="assets/images/resource/history-1.jpg" alt="" />
                  <div class="dots-box"></div>
                </figure>
                <div class="content-box">
                  <div class="dots-box active"></div>
                 
                  <div class="text">
                    <h3>Choose your Job Track</h3>
                    <p>
                      Join our fast-growing company and be part of a dynamic
                      team driving innovation and success in the industry.
                    </p>
                  </div>
                </div>
                <figure class="image-box ">
                  <img src="assets/images/resource/history-2.jpg" alt="" />
                  <div class="dots-box"></div>
                </figure>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 column">
              <div class="inner-box">
                <div class="content-box">
                  
                  <div class="text">
                    <h3>Fundamentals</h3>
                    <p>
                      Established in Bengaluru, where innovation meets
                      opportunity, we're proud to serve clients with excellence
                      and dedication
                    </p>
                  </div>
                </div>
                <figure class="image-box">
                  <img src="assets/images/resource/history-3.jpg" alt="" />
                </figure>
                <div class="content-box">
                 
                  <div class="text">
                    <h3>
Placement Assistance for Your Job </h3>
                    <p>
                      Proud recipients of the Best Staffing Talent Award,
                      showcasing our commitment to excellence and unmatched
                      service in the industry.
                    </p>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="about-section sec-pad" style={{marginTop:"-150px"}}>
        <div
          class="pattern-layer"
          style={{ backgroundImage: "url('assets/images/shape/pattern-5.png)" }}
        ></div>
        <div class="auto-container">
          <div class="inner-container">
            <div class="row clearfix">
              <div class="col-lg-5 col-md-12 col-sm-12 content-column">
                <figure><img src="assets/images/long-term-1.png"></img></figure>
              </div>
              <div class="col-lg-7 col-md-12 col-sm-12 content-column" style={{padding:"20px"}}><br></br>
                <h2>Looking to discover, launch, or accelerate your career?</h2><br></br>
                <p style={{fontSize:"18px"}}>A Guide To Internships For Students: Landing, Navigating, And Making The Most Of Them</p><br></br>
                <ul style={{fontSize:"24px"}}>
                  <li><i class="flaticon-right-arrow"></i> Focus on building skills:<br></br>
                  <p>No exams, quizzes, or busy work—just real projects that drive business impact.</p>
                  </li><br></br>
                  <li><i class="flaticon-right-arrow"></i> Set your own hours:<br></br>
                  <p>100% flexible and remote. 10-15 hours a week.</p>
                  </li><br></br>
                  <li><i class="flaticon-right-arrow"></i> Real work, real learning:<br></br>
                  <p>Develop essential career skills that you can only gain through real work. Learn by doing.</p>
                  </li><br></br>
                  <li><i class="flaticon-right-arrow"></i> Accelerate your career:<br></br>
                  <p>Nearly half of externs have landed jobs or internships with Fortune 1000 companies.</p>
                  </li>  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="about-section sec-pad" style={{marginTop:"-150px"}}>
        
        <div class="auto-container">
          <div class="inner-container" style={{padding:"20px",backgroundColor:"#f0e6e0"}}>
            <div class="row clearfix">
              <div class="col-lg-4 col-md-12 col-sm-12 content-column">
                              <h2>Exploring career paths?</h2>
                              <p>No experience? No problem. Tackle real projects. Discover your future.</p>
              </div>
              <div class="col-lg-4 col-md-12 col-sm-12 content-column">
                        <h2>Chasing your dream job?</h2>
                        <p>You know where you want to be. We're here to give you that boost.</p>
              </div>
              <div class="col-lg-4 col-md-12 col-sm-12 content-column">
                        <h2>Looking to upskill?</h2>
                        <p>Strengthen your career ladder. Grow skills at your pace, anywhere.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="chooseus-section sec-pad-2" style={{marginTop:"-200px"}}>
        <h2 style={{textAlign:"center"}}>Ignite Your Tech Career Today: Learn, Grow, and Get Placed</h2><br></br><br></br>
            <div class="auto-container">
                <div class="row clearfix">
                 
                    <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                        <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                 
                                <h3>6 months of Rigorous training</h3>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                        <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                
                                <h3>Deployable from Day 1</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                        <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                
                                <h3>Client Based Training</h3>
                                
                            </div>
                        </div>
                    </div><br></br><br></br><br></br><br></br><br></br><br></br>
                    <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                        <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                
                                <h3>8 Hours TrainingPer Day</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                        <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                
                                <h3>Direct Client Payroll</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                        <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div class="inner-box">
                                
                                <h3>MIN: 3,60,000 CTC PACKAGE</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section
              class="about-section sec-pad"
              style={{ marginTop: "-100px" }}
            >
              <div class="pattern-layer"></div>
              <div class="auto-container">
                <div class="inner-container">
                  <div class="row clearfix">
                    <div
                      class="col-lg-4 col-md-12 col-sm-12 content-column"
                      style={{
                        lineHeight: "150%",
                        backgroundColor: "#fdf9f3",
                        borderRight: "3px solid #F8EEDF",
                      }}
                    >
                      <div
                        id="content_block_2"
                        style={{ padding: "30px", textAlign: "center" }}
                      >
                        <h4>
                          Price: ₹
                          <span style={{ textDecoration: "line-through" }}>
                            149,999
                          </span>
                        </h4>
                        <h1>₹119,999</h1>
                        <p>17% OFF , Save ₹30000.</p>
                        <br></br>
                        <h2>Duration: 6 Months</h2>
                        <h4>Client Project Based - <br></br>8 Hours Per Day</h4>
                        <h4>Stipend: 15000 Per Month</h4>
                        <br></br>
                        <div class=" form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Enroll Now
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-12 col-sm-12 content-column">
                      <div id="content_block_3">
                        <div class="content-box">
                          <div class="sec-title">
                            <h3 style={{ fontSize: "42px" }}>
                            Master Full Stack Development & Long Term Internship

                            </h3>
                            <hr />
                          </div>
                          <div
                            class="container-fluid"
                            style={{ width: "100%" }}
                          >
                            <div class="row">
                              <div class="col-md-6">
                                <ul>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Angular
                                    Fundamentals
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Components and Directives
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Services and Dependency Injection
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Routing
                                    and Navigation
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Forms
                                    and Validation
                                  </li>
                                </ul>
                              </div>
                              <div class="col-md-6">
                                <ul>
                                  <li>
                                    <i class="flaticon-right-arrow"></i> HTTP
                                    Requests and APIs
                                  </li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Authentication and Authorization
                                  </li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Optimization and Performance
                                  </li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i> Testing
                                    and Debugging
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        <Clients />
      <section class="awards-section">
        <div class="auto-container">
          <div class="sec-title centred">
            <h2>500+ student’s life impacted and continuing</h2>
            <p>
              Learn from our top instructors Get a Guaranteed Job upto 25 LPA
            </p>
          </div>
          <div class="row clearfix">
            <div class="col-lg-3 col-md-6 col-sm-12 inner-column">
              <div class="card" style={{ width: "18rem", textAlign: "center" }}>
                <img
                  class="card-img-top"
                  src="assets/images/team/team-1.jpg"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <h3 class="card-title" style={{ fontWeight: "bold" }}>
                    Naveen Kumar
                  </h3>
                  <p class="card-text">MERN Full Stack Developer</p>
                  {/* <h5 class="card-title">TCS</h5> */}
                  <p>Package: 6.8 LPA</p>

                  {/* <a href="#" class="btn btn-primary">
                   View More
                  </a> */}
                  <img src="assets/images/clients/tcs.png" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 inner-column">
              <div class="card" style={{ width: "18rem", textAlign: "center" }}>
                <img
                  class="card-img-top"
                  src="assets/images/team/team-2.jpg"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <h3 class="card-title" style={{ fontWeight: "bold" }}>
                    Vinay{" "}
                  </h3>
                  <p class="card-text">Java Developer</p>
                  {/* <h5 class="card-title">Microsoft</h5> */}
                  <p>Package: 10.5 LPA</p>
                  {/* <a href="#" class="btn btn-primary">
                   View More
                  </a> */}
                  <img
                    src="assets/images/clients/microsoft.png"
                    alt=""
                    style={{ marginTop: "40px" }}
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 inner-column">
              <div class="card" style={{ width: "18rem", textAlign: "center" }}>
                <img
                  class="card-img-top"
                  src="assets/images/team/team-3.jpg"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <h3 class="card-title" style={{ fontWeight: "bold" }}>
                    Ramya
                  </h3>
                  <p class="card-text">Softeware Engineer</p>
                  {/* <h5 class="card-title">Infosys</h5> */}
                  <p>Package: 10 LPA</p>
                  {/* <a href="#" class="btn btn-primary">
                   View More
                  </a> */}
                  <img
                    src="assets/images/clients/infosys.png"
                    alt=""
                    style={{ width: "120px", marginTop: "38px" }}
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 inner-column">
              <div class="card" style={{ width: "18rem", textAlign: "center" }}>
                <img
                  class="card-img-top"
                  src="assets/images/team/team-4.jpg"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <h4 class="card-title" style={{ fontWeight: "bold" }}>
                    Rakesh
                  </h4>
                  <p class="card-text">Web Developer</p>
                  {/* <h5 class="card-title">Google</h5> */}
                  <p>Package: 8.3 LPA</p>
                  {/* <a href="#" class="btn btn-primary">
                   View More
                  </a> */}
                  <img
                    src="assets/images/clients/google.png"
                    alt=""
                    style={{ width: "150px", marginTop: "38px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LongtermInternship;
