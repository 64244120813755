import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import OwlCarousel from "react-owl-carousel2";
import Clients from "../Clients/Clients";

const Jobseekers = () => {
  return (
    <>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-120px" }}>
                  <div
                    class="col-lg-6 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white", fontWeight: "300" }}>
                          Tailored software solutions designed to meet your unique needs, enhancing efficiency, innovation, and business growth.
                          </h2>
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        // boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/banner/jobseekers.jpg"></img>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- solutions-problems --> */}
      <section className="" style={{ marginTop: "-80px" }}>
              <div className="">
                <div className="sec-title centred mt-5 pt-5 ">
                  <span className="top-title">Our Service Line</span>
                  <h2>
                    Software Solutions Built Around Your Needs
                    <p>
                      Our custom software solutions are designed to meet the unique
                      needs of your business, optimizing operations and <br></br>
                      improving customer experiences. Whether you need CRM systems,
                      e-commerce platforms, or mobile apps, we deliver <br></br>
                      high-performance solutions that are scalable, secure, and easily
                      integrated into your existing infrastructure. With a <br></br>
                      focus on innovation, we help businesses stay ahead in a
                      competitive landscape.
                    </p>
                  </h2>
                </div>
              </div>
            </section>
      
            <div className="innerContent-wrap mb-5">
              <div className="container">
                <div className="class-wrap">
                  <ul className="row unorderList">
                    <li className="col-lg-3 col-md-6 ">
                      <div className="class_box shadow">
                        <div className="class_Img">
                          <img
                            src="assets/images/courses/ui-ux.png"
                            alt=""
                            style={{
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "12px 12px 0 0",
                            }}
                          />
                        </div>
                        <div className="path_box">
                          <h4>
                            <a href="#">UI / UX Design </a>
                          </h4>
                          <p>
                            Design High-Impact User Experiences. Research, design, and
                            prototype effective, visually-driven websites and apps.
                          </p>
                        </div>
                        <div className="row ml-1 pb-2">
                          <Link
                            to="#"
                            className="class_link"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="viewmore p-4 m-1"
                              style={{ width: "90%", textAlign: "center" }}
                            >
                              <a href="#" style={{ display: "block", width: "90%" }}>
                                View More
                              </a>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li className="col-lg-3 col-md-6">
                      <div className="class_box shadow">
                        <div className="class_Img">
                          <img
                            src="assets/images/courses/react-front-end.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "12px 12px 0 0",
                            }}
                          />
                        </div>
                        <div className="path_box">
                          <h4>
                            <a href="#">React Web Development</a>
                          </h4>
                          <p>
                          React Web Development creates fast, dynamic user interfaces using reusable components for efficient web apps.
                          </p>
                        </div>
                        <div className="row ml-1 pb-2">
                          <Link
                            to="#"
                            className="class_link"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="viewmore p-4 m-1"
                              style={{ width: "90%", textAlign: "center" }}
                            >
                              <a href="" style={{ display: "block", width: "90%" }}>
                                View More
                              </a>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li className="col-lg-3 col-md-6">
                      <div className="class_box shadow">
                        <div className="class_Img">
                          <img
                            src="assets/images/courses/react-native.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "12px 12px 0 0",
                            }}
                          />
                        </div>
                        <div className="path_box">
                          <h4>
                            <a href="">React Native Development</a>
                          </h4>
                          <p>
                          A React Native development creates cross-platform mobile apps using React Native for iOS and Android.
                          </p>
                        </div>
                        <div className="row ml-1 pb-2">
                          <Link
                            to="#"
                            className="class_link"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="viewmore p-4 m-1"
                              style={{ width: "90%", textAlign: "center" }}
                            >
                              <a href="" style={{ display: "block", width: "90%" }}>
                                View More
                              </a>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li className="col-lg-3 col-md-6">
                      <div className="class_box shadow">
                        <div className="class_Img">
                          <img
                            src="assets/images/courses/angular.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "12px 12px 0 0",
                            }}
                          />
                        </div>
                        <div className="path_box">
                          <h4>
                            <a href="">Angular Web Development</a>
                          </h4>
                          <p>
                          Angular web development focuses on building dynamic, scalable single-page applications (SPAs) using the Angular framework. 
                          </p>
                        </div>
                        <div className="row ml-1 pb-2">
                          <Link
                            to="#"
                            className="class_link"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="viewmore p-4 m-1"
                              style={{ width: "90%", textAlign: "center" }}
                            >
                              <a href="" style={{ display: "block", width: "90%" }}>
                                View More
                              </a>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li className="col-lg-3 col-md-6">
                      <div className="class_box shadow">
                        <div className="class_Img">
                          <img
                            src="assets/images/courses/dotnet.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "12px 12px 0 0",
                            }}
                          />
                        </div>
                        <div className="path_box">
                          <h4>
                            <a href="">.NET FullStack Development</a>
                          </h4>
                          <p>
                            Build Fullstack webapp with .NET technologies. Master the
                            intricacies of .NET core and ASP.NET backend development.
                          </p>
                        </div>
                        <div className="row ml-1 pb-2">
                          <Link
                            to="#"
                            className="class_link"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="viewmore p-4 m-1"
                              style={{ width: "90%", textAlign: "center" }}
                            >
                              <a href="" style={{ display: "block", width: "90%" }}>
                                View More
                              </a>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li className="col-lg-3 col-md-6">
                      <div className="class_box shadow">
                        <div className="class_Img">
                          <img
                            src="assets/images/courses/mern.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "12px 12px 0 0",
                            }}
                          />
                        </div>
                        <div className="path_box">
                          <h4>
                            <a href="">MERN FullStack Development</a>
                          </h4>
                          <p>
                            You will learn to build dynamic, scalable web applications
                            using MongoDB, Express.js, React, and Node.js
                          </p>
                        </div>
                        <div className="row ml-1 pb-2">
                          <Link
                            to="#"
                            className="class_link"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="viewmore p-4 m-1"
                              style={{ width: "90%", textAlign: "center" }}
                            >
                              <a href="" style={{ display: "block", width: "90%" }}>
                                View More
                              </a>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li className="col-lg-3 col-md-6">
                      <div className="class_box shadow">
                        <div className="class_Img">
                          <img
                            src="assets/images/courses/Java-Full-Stack.png"
                            alt=""
                            style={{
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "12px 12px 0 0",
                            }}
                          />
                        </div>
                        <div className="path_box">
                          <h4>
                            <a href="">Full Stack Java DEvelopment</a>
                          </h4>
                          <p>
                            Streamline your Java application development with
                            simplicity, speed, and robustness for modern, scalable
                            projects.
                          </p>
                        </div>
                        <div className="row ml-1 pb-2">
                          <Link
                            to="#"
                            className="class_link"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="viewmore p-4 m-1"
                              style={{ width: "90%", textAlign: "center" }}
                            >
                              <a href="" style={{ display: "block", width: "90%" }}>
                                View More
                              </a>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li className="col-lg-3 col-md-6">
                      <div className="class_box shadow">
                        <div className="class_Img">
                          <img
                            src="assets/images/courses/devops.jpg"
                            alt=""
                            style={{
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "12px 12px 0 0",
                            }}
                          />
                        </div>
                        <div className="path_box">
                          <h4>
                            <a href="">Advanced DevOps </a>
                          </h4>
                          <p>
                          Advanced DevOps improves automation, CI/CD, IaC, and cloud-native practices for faster, high-quality development
                          </p>
                        </div>
                        <div className="row ml-1 pb-2">
                          <Link
                            to="#"
                            className="class_link"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="viewmore p-4 m-1"
                              style={{ width: "90%", textAlign: "center" }}
                            >
                              <a href="" style={{ display: "block", width: "90%" }}>
                                View More
                              </a>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </li>
      
                    
                  </ul>
                </div>
              </div>
            </div>
      {/* <!-- findjob-section end --> */}

    </>
  );
};

export default Jobseekers;
