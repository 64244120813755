import React from 'react'
import { Link } from 'react-router-dom'

const Jobseeker = () => {
  return (
    <div className="container my-2"> {/* Added container class for margins and centered content */}
      <div className="border"> {/* Added padding inside the bordered container */}
        <div className="container my-2">
          <div className="row">
            <div className="col">
              <h2>Job Seeker</h2>
            </div>
            <div className="col-auto">
              <Link to="/home-add-employers" className="class_link">
                <div className="viewmore p-4">
                  <a href="">Add Job Seeker</a>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">First</th>
              <th scope="col">Last</th>
              <th scope="col">Handle</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td colSpan="2">Larry the Bird</td>
              <td>@twitter</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Jobseeker
