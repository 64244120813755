import React from "react";
import { Link } from "react-router-dom";

const Courses = () => {
  return (
    <div className="container my-2"> {/* Use container class for proper margins */}
      <div className="border "> {/* Add padding and border for better spacing */}
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>Courses</h2>
            </div>
            <div className="col-auto">
              <Link to="/home-add-courses" className="class_link"> {/* Corrected className usage */}
                <div className="viewmore p-4"> {/* Adjusted padding */}
                 <a>Add Courses </a> 
                </div>
              </Link>
            </div>
          </div>
        </div>

        <table className="table"> {/* Corrected className usage */}
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">First</th>
              <th scope="col">Last</th>
              <th scope="col">Handle</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td colSpan="2">Larry the Bird</td> {/* Corrected colspan to colSpan */}
              <td>@twitter</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Courses;
