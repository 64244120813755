import React from "react";
import { Link } from "react-router-dom";

const VLSIphysicaldesign = () => {
  return (
    <div>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-150px" }}>
                  <div
                    class="col-lg-7 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white", fontWeight: "300" }}>
                            VLSI Physical Design
                          </h2>
                          <p style={{ color: "white", fontWeight: "200" }}>
                            This VLSI Physical Design online training program is
                            designed to equip participants with the essential
                            skills and knowledge required to excel in the
                            semiconductor industry. By the end of the course,
                            participants will have a solid understanding of the
                            entire VLSI physical design process and will be
                            prepared to tackle real-world design challenges.
                            This comprehensive online training program in VLSI
                            Physical Design is tailored for engineers and
                            students aiming to deepen their understanding and
                            enhance their skills in the domain of semiconductor
                            design and manufacturing.
                          </p>{" "}
                          <br />
                          <div className="row ml-1 pb-2">
                            <Link to="" className="class_link">
                              <div className="viewmore p-4 m-1 ">
                                <a href="">View More</a>
                              </div>
                            </Link>
                            <Link to="" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Talk to Our Advisor</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/courses/vlsi-physical-design.jpg"></img>
                      {/* <form
                      // method="post"
                      // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                      id="contact-form"
                      class="default-form form-group"
                    >
                      <div class=" clearfix">
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            // name="username"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="email"
                            // name="email"
                            placeholder="Email Address *"
                            required=""
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Phone *"
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            // name="subject"
                            required=""
                            placeholder="Subject"
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- React Course -details --> */}
      <section class="job-details">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-8 col-md-12 col-sm-12 content-side">
              <div class="job-details-content">
                <div class="text">
                  <h2>Course Description</h2>
                  <p>
                    Fundamental concepts in Digital abstraction, Static
                    discipline, MOSFET switch, CMOS basics, Digital circuit
                    speed, NMOS logic, CMOS logic, Combinational logic,
                    Sequential logic, Synchronous sequential design, Timing
                    awareness, Setup/Hold requirement significance, Asynchronous
                    circuits, Metastability, Synchronization, Logic synthesis
                    fundamentals, Advanced logic synthesis (PLE based), Floor
                    planning, Power planning, Placement, Clock tree synthesis,
                    Routing, Signal integrity, IR-drop analysis, OCV analysis,
                    Static timing analysis and advanced Physical design concepts
                    like Low power design techniques.
                  </p>
                  <p>
                    The trainees get to work on 5 to 6 different designs. The
                    assignments are designed in such a way that trainees have a
                    clear understanding about handling the design from Synthesis
                    to Sign-off within the given specification limits of Area,
                    Timing and Power.
                  </p>
                  {/* <h3>Responsibilities</h3>
                <ul class="list clearfix">
                  <li>
                  Developing user interface components using React.js concepts and best practices.
                  </li>
                  <li>
                  Translating UI/UX design wireframes into high-quality code.
                  </li>
                  <li>
                  Optimizing components for maximum performance across various browsers and devices.
                  </li>
                  <li>
                  Collaborating with back-end developers to integrate front-end components with server-side logic.
                  </li>
                </ul>
                <h3>Skills</h3>
                <ul class="list clearfix">
                  <li>
                  Proficiency in HTML, CSS, and JavaScript.
                  </li>
                  <li>
                  Strong understanding of UI/UX design principles.
                  </li>
                  <li>
                  Experience with React.js and its ecosystem (React Router, Redux, etc.).
                  </li>
                  <li>
                  Ability to create responsive and mobile-friendly interfaces.
                  </li>
                  <li>
                  Familiarity with version control systems (e.g., Git).
                  </li>
                </ul> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div class="job-sidebar">
                <div
                  class=" form-column"
                  style={{
                    boxShadow: "0px 0px 1px 1px lightblue",
                    borderRadius: "10px",
                    borderTop: "10px solid #662d91",
                    paddingBottom: "3px",
                  }}
                >
                  <div class="form-inner" style={{ margin: "20px" }}>
                    <form
                      // method="post"
                      // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                      id="contact-form"
                      class="default-form"
                    >
                      <div class=" clearfix">
                        <h4 style={{ textAlign: "center" }}>
                          Free Career Counselling
                        </h4>
                        <p style={{ textAlign: "center" }}>
                          We are happy to help you 24/7
                        </p>

                        <div class=" form-group mt-3">
                          <input
                            type="text"
                            // name="username"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>

                        <div class=" form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Phone *"
                          />
                        </div>
                        <div class=" form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Email Id *"
                          />
                        </div>
                        {/* <div class=" form-group">
                    <input
                      type="text"
                      // name="location"
                      placeholder="Location "
                    />
                  </div>
                  <div class=" form-group">
                    <input
                      type="text"
                      // name="subject"
                      required=""
                      placeholder="Subject"
                    />
                  </div>
                  <div class=" form-group">
                    <textarea
                      // name="message"
                      placeholder="Message ..."
                    ></textarea>
                  </div> */}
                        <div class=" form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Enroll Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                {/* <div class="sidebar-widget support-widget mt-5">
                <div class="widget-content centred">
                  <div class="pattern-layer">
                    <div
                      class="pattern-1"
                      style={{
                        backgroundImage:
                          "url(assets/images/shape/pattern-44.png)",
                      }}
                    ></div>
                    <div
                      class="pattern-2"
                      style={{
                        backgroundImage:
                          "url(assets/images/shape/pattern-45.png)",
                      }}
                    ></div>
                  </div>
                  <figure class="image-box">
                    <img src="assets/images/resource/author-2.png" alt="" />
                  </figure>
                  <h3>Shahid</h3>
                  <span class="designation">Trainer</span>
                  <ul class="support-info">
                    <li>
                      Call: <a href="tel:8004561234">(800) 456-1234</a>
                    </li>
                    <li>
                      Mail:{" "}
                      <a href="mailto:baldwin@example.com">
                        shahid@gmail.com
                      </a>
                    </li>
                  </ul>
                  <ul class="social-links">
                    <li>
                      <a href="job-details.html">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="job-details.html">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="job-details.html">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 column">
              <div class="related-job">
                <h2 style={{ textAlign: "center" }}>
                  Why VLSI Physical Design Training from Ideants
                </h2>
              </div>
            </div>
            <section
              class="chooseus-section alternet-2 sec-pad-2"
              style={{ marginTop: "-130px" }}
            >
              <div class="auto-container">
                <div class="row clearfix">
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-44.png" alt=""></img></figure> */}
                        <h3>Live Interactive Learning</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> World-Class
                            Instructors
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Expert-Led
                            Mentoring Sessions
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Instant doubt
                            clearing
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="200ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-45.png" alt=""></img></figure> */}
                        <h3>Lifetime Access</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Course Access
                            Never Expires
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Free Access to
                            Future Updates
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Unlimited
                            Access to Course Content
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="400ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-46.png" alt=""></img></figure> */}
                        <h3>24x7 Live & desk Support.</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> One-On-One
                            Learning Assistance
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Help Desk
                            Support
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Resolve Doubts
                            in Real-time
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                      <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                          <div class="inner-box">
                              <figure class="icon-box"><img src="assets/images/icons/icon-47.png" alt=""></img></figure>
                              <h3>Broad Network</h3>
                              <p>Business it will frequently pleasures repudiated.</p>
                              <a href="index-2.html"><i class="flaticon-right-arrow"></i>More Details</a>
                          </div>
                      </div>
                  </div> */}
                </div>
              </div>
            </section>
            <section
              class="chooseus-section alternet-2 sec-pad-2"
              style={{ marginTop: "-240px" }}
            >
              <div class="auto-container">
                <div class="row clearfix">
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-44.png" alt=""></img></figure> */}
                        <h3>Hands-On Project Based Learning</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i>{" "}
                            Industry-Relevant Projects
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Course Demo
                            Dataset & Files
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Quizzes &
                            Assignments
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="200ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-45.png" alt=""></img></figure> */}
                        <h3>Industry Recognised Certification</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Ideants
                            Training Certificate
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Graded
                            Performance Certificate
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Certificate of
                            Completion
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="400ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-46.png" alt=""></img></figure> */}
                        <h3>Opportunity in Top MNCs</h3>
                        <li>
                          <i class="flaticon-right-arrow"></i> Physical Design
                          Engineer
                        </li>
                        <li>
                          <i class="flaticon-right-arrow"></i> Test Engineer
                        </li>
                        <li>
                          <i class="flaticon-right-arrow"></i> Product Engineer
                        </li>
                        <br></br>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                      <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                          <div class="inner-box">
                              <figure class="icon-box"><img src="assets/images/icons/icon-47.png" alt=""></img></figure>
                              <h3>Broad Network</h3>
                              <p>Business it will frequently pleasures repudiated.</p>
                              <a href="index-2.html"><i class="flaticon-right-arrow"></i>More Details</a>
                          </div>
                      </div>
                  </div> */}
                </div>
              </div>
            </section>
            <div
              class="col-lg-12 col-md-12 col-sm-12 column"
              style={{ marginTop: "-50px" }}
            >
              <div class="related-job">
                <h2 style={{ textAlign: "center" }}>
                  Instructor-led VLSI Physical Design live online Training
                  Schedule & Flexible batches for you
                </h2>
              </div>
            </div>
            <section
              class="about-section sec-pad"
              style={{ marginTop: "-100px" }}
            >
              <div class="pattern-layer"></div>
              <div class="auto-container">
                <div class="inner-container">
                  <div class="row clearfix">
                    <div
                      class="col-lg-4 col-md-12 col-sm-12 content-column"
                      style={{
                        lineHeight: "150%",
                        backgroundColor: "#fdf9f3",
                        borderRight: "3px solid #F8EEDF",
                      }}
                    >
                      <div
                        id="content_block_2"
                        style={{ padding: "30px", textAlign: "center" }}
                      >
                        {/* <h4>
                          Price: ₹
                          <span style={{ textDecoration: "line-through" }}>
                            29,999
                          </span>
                        </h4> */}
                        <h1>₹1,50,000/- [+ 18% GST].</h1>
                        {/* <p>17% OFF , Save ₹5000.</p> */}
                        <br></br>
                        {/* <h2>Duration: 45 Days</h2> */}
                        <h4>May 22 - Orientation Class</h4>
                        <br></br>
                        <div class=" form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Enroll Now
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-12 col-sm-12 content-column">
                      <div id="content_block_3">
                        <div class="content-box">
                          <div class="sec-title">
                            <h3 style={{ fontSize: "42px" }}>
                              VLSI Physical Design Certification Training Course
                            </h3>
                            <hr />
                          </div>
                          <div
                            class="container-fluid"
                            style={{ width: "100%" }}
                          >
                            <div class="row">
                              <div class="col-md-6">
                                <ul>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Introduction to VLSI Physical Design
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Design
                                    Flow and CAD Tools
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i>
                                    Partitioning and Floorplanning
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Placement
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Clock
                                    Tree Synthesis (CTS)
                                  </li>
                                </ul>
                              </div>
                              <div class="col-md-6">
                                <ul>
                                  <li>
                                    <i class="flaticon-right-arrow"></i> Routing
                                  </li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i> Design
                                    for Manufacturability (DFM)
                                  </li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i> Timing
                                    Analysis
                                  </li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i> Power
                                    Analysis and Optimization
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              class="about-section sec-pad"
              style={{ marginTop: "-190px", width: "100%" }}
            >
              <div class="pattern-layer"></div>
              <div class="auto-container">
                <div class="inner-container">
                  <div class="row clearfix">
                    <div
                      class="col-lg-6 col-md-12 col-sm-12 content-column"
                      style={{ borderRight: "2px solid lightgrey" }}
                    >
                      <div id="content_block_2" style={{ padding: "30px" }}>
                        <h3 style={{ textAlign: "center", fontSize: "36px" }}>
                          Skills Covered
                        </h3>
                        <hr></hr>

                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Foundational
                            Knowledge
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Design Flow
                            Proficiency
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Floorplanning
                            and Partitioning
                          </li>
                          <li>
                            {" "}
                            <i class="flaticon-right-arrow"></i> Placement
                            Techniques
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Clock Tree
                            Synthesis (CTS)
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i>Routing Skills
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                                            <div id="content_block_3">
                                                <div class="content-box">
                                                    <div class="sec-title">
                                                        <h3
                                                            style={{ textAlign: "center", fontSize: "36px" }}
                                                        >
                                                            Tools Covered
                                                        </h3>
                                                        <hr></hr>
                                                        <img src="assets/images/courses/figma.png"></img>
                                                        <img src="assets/images/courses/balsamiq.png"></img>
                                                        <img src="assets/images/courses/sketch.png"></img>
                                                        <img src="assets/images/courses/invision.png"></img>
                                                        <img src="assets/images/courses/miro.png"></img>
                                                        <img src="assets/images/courses/mural.png"></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </section>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div class=" form-group message-btn centred">
                    <button
                      class="theme-btn-one"
                      type="submit"
                      // name="submit-form"
                    >
                      Short term Internship Program Details
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class=" form-group message-btn centred">
                    <button
                      class="theme-btn-one"
                      type="submit"
                      // name="submit-form"
                    >
                      Long term Internship Program Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <section
              class="faq-section text-left"
              style={{ width: "100%", marginTop: "-20px" }}
            >
              <div class="container-fluid text-left" style={{ width: "100%" }}>
                <div class="row">
                  <div class="col-md-8">
                    <section
                      class="btc-accordion text-left"
                      style={{ width: "100%" }}
                    >
                      <div
                        class="container text-left"
                        style={{ width: "100%" }}
                      >
                        <h1 class="text-center text-black">
                          VLSI Physical Design Certification Course Curriculum
                        </h1>
                        <br />
                        <p class="text-center text-black">
                          <h4>Curriculum Designed by Experts</h4>{" "}
                        </p>

                        <div class="row">
                          <div class="col-md-12">
                            <div class="accordion mb-2" id="accordionExample">
                              <div class="card">
                                <div
                                  class="card-header collapsed"
                                  id="headingOne"
                                >
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                  >
                                    <h5 class="py-2">
                                      {" "}
                                      1. Introduction to VLSI (5 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseOne"
                                  class="collapse"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            VLSI Design Flow
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            ASIC vs FPGA{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            RTL Design Methodologies
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to ASIC Verification
                                            Methodologies
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            VLSI Design Flow Steps - Demo
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Designing integrated circuits
                                              using tools like Verilog and CAD
                                              software.
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Mastering circuit design, layout
                                              creation, simulation, and
                                              fabrication processes in VLSI
                                              enables the development of
                                              advanced electronic systems and
                                              devices.{" "}
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Basic Semiconductor Physics
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Digital Logic Design
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Integrated Circuit (IC)
                                              Fabrication
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingTwo">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    <h5 class="py-2">
                                      {" "}
                                      2. Introduction to Physical Design (7
                                      Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseTwo"
                                  class="collapse"
                                  aria-labelledby="headingTwo"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Partitioning{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Floorplan & Powerplan
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Placement{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Static Timing Analysis (Signal
                                            Integrity and Cross Talk Issues)
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Clock Tree Synthesis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Routing{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Physical Verification & Design
                                            Signoff
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Physical Design in VLSI involves
                                              the transformation of a logical
                                              design into a physical
                                              representation, specifying the
                                              layout of components on a chip.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Overview
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Floorplanning
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Clock Tree Synthesis
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingThree">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    <h5 class="py-2">
                                      3. Advanced Digital Design(10 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseThree"
                                  class="collapse"
                                  aria-labelledby="headingThree"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to Digital Electronics
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Arithmetic Circuits
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Data processing Circuits
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Universal Logic Elements
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Combinational Circuits - Design and
                                            Analysis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Latches and Flip flops
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Shift Registers and Counters
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Sequential Circuits - Design and
                                            Analysis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Memories and PLD
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Finite State Machine
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Advanced Digital Design delves
                                              into complex digital system
                                              architectures, focusing on
                                              optimization, performance
                                              enhancement, and integration of
                                              advanced features.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              High-Speed Design Techniques
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingFour">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                  >
                                    <h5 class="py-2">
                                      4. Introduction to Linux (4 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFour"
                                  class="collapse"
                                  aria-labelledby="headingFour"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Components of the UNIX system
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Directory Structure
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Utilities and Commands
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Vi Editor
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Linux is a widely-used open-source
                                              operating system kernel, developed
                                              initially by Linus Torvalds in
                                              1991, offering versatility,
                                              stability, and security for
                                              various computing environments.
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Known for its robustness,
                                              flexibility, and support for a
                                              wide range of hardware
                                              architectures, Linux powers
                                              everything from personal computers
                                              to servers, mobile devices, and
                                              embedded systems.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              History and Philosophy
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Kernel and Distributions
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Command-Line Interface (CLI)
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingFive">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                  >
                                    <h5 class="py-2">
                                      5. Verilog HDL - RTL Coding and Synthesis
                                      (7 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFive"
                                  class="collapse"
                                  aria-labelledby="headingFive"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to Verilog HDL
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Data Types
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Verilog Operators{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Assignments
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Finite State Machine{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Advanced Verilog for Verification
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Synthesis Coding Style
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Verilog Hardware Description
                                              Language (HDL) is widely used for
                                              Register Transfer Level (RTL)
                                              coding, facilitating the design
                                              and synthesis of digital circuits.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Module Declaration
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Combinational Logic
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Sequential Logic{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Synthesis Considerations{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingSix">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseSix"
                                    aria-expanded="false"
                                    aria-controls="collapseSix"
                                  >
                                    <h5 class="py-2">
                                      6. Verilog Mini Project - RTL Coding(4
                                      Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseSix"
                                  class="collapse"
                                  aria-labelledby="headingSix"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Project Specification Analysis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Understanding the architecture
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Module-level implementation and
                                            verification
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Building the top-level module
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Implementing a mini project in
                                              Verilog involves applying Register
                                              Transfer Level (RTL) coding
                                              techniques to design digital
                                              circuits.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Project Definition
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Module Declaration
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Data Types and Registers
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Combinational and Sequential Logic{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingSeven">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="collapseSeven"
                                  >
                                    <h5 class="py-2">
                                      7.Design for Testability - DFT(8 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseSeven"
                                  class="collapse"
                                  aria-labelledby="headingSeven"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to DFT
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Types of Testing
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Basic Testing Principles
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Fault Collapsing
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            DFT Techniques - AD-hoc Techniques
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Structured Techniques
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            BIST & Boundary scan
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to Siemens EDA's
                                            Tessent Shell
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              DFT refers to the process of
                                              incorporating features into
                                              integrated circuit designs to
                                              facilitate testing and diagnosis
                                              during manufacturing and in-field
                                              operation.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Scan Chain Insertion
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Built-In Self-Test (BIST)
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Boundary Scan (JTAG)
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingEight">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseEight"
                                    aria-expanded="false"
                                    aria-controls="collapseEight"
                                  >
                                    <h5 class="py-2">
                                      8.CMOS Devices and Technology (10 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseEight"
                                  class="collapse"
                                  aria-labelledby="headingEight"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            MOSFET Operation and Characteristics
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Non-ldeal characteristics
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            BJT vs FET
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            CMOS Characteristics
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            CMOS Circuit design
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Transistor sizing & Scaling
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Layout and Stick Diagrams
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            CMOS Processing Steps
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            IC Fabrication
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Advancements in CMOS Technology -
                                            Current Trends
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              CMOS technology combines NMOS and
                                              PMOS transistors for low power
                                              consumption and high efficiency in
                                              modern integrated circuits.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Low Power Consumption
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              High Efficiency: The complementary
                                              operation of NMOS and PMOS
                                              transistors in CMOS circuits leads
                                              to efficien
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Scalability and Performance
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingNine">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseNine"
                                    aria-expanded="false"
                                    aria-controls="collapseNine"
                                  >
                                    <h5 class="py-2">
                                      9.Python Scripting ( 11 topics ){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseNine"
                                  class="collapse"
                                  aria-labelledby="headingNine"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to Python
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Python Data Types
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Numerical Datatypes
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Boolean Datatypes
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            String Datatypes
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            List Datatypes
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Tuple
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Dictionaries
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Set Datatypes
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Python Operators
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Python Modules Abstraction levels
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Python scripting allows for
                                              automation, data analysis, and the
                                              creation of versatile applications
                                              with its easy-to-read syntax and
                                              extensive libraries.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Automation
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Versatility
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Ease of Use
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingTen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseTen"
                                    aria-expanded="false"
                                    aria-controls="collapseTen"
                                  >
                                    <h5 class="py-2">
                                      10.Tcl/Tk Scripting (9 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseTen"
                                  class="collapse"
                                  aria-labelledby="headingTen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Tcl overview
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Tcl vs Perl
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Evaluating TCL scripts under Unix
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            TcI syntax
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Datatypes{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Operators
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Branching & looping construct
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Subroutines
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            File operations
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Tcl/Tk scripting is used for rapid
                                              prototyping and developing
                                              graphical user interfaces (GUIs)
                                              due to its simplicity and powerful
                                              integration capabilities.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              GUI Development{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Simplicity and Integration{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Rapid Prototyping{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingEleven">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseEleven"
                                    aria-expanded="false"
                                    aria-controls="collapseEleven"
                                  >
                                    <h5 class="py-2">
                                      11.Static Timing Analysis (Self-Paced)(10
                                      Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseEleven"
                                  class="collapse"
                                  aria-labelledby="headingEleven"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to STA
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Comparison with DTA
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Timing Path and Constraints
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Different types of clocks
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Clock domain and Variations
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Clock Distribution Networks
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            How to fix timing failure
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Timing Closure
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Timing ECO's for each type of
                                            violation
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            What is hierarchical timing closure
                                            - time budgeting and clocking
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Static Timing Analysis (STA) is a
                                              method used to verify the timing
                                              performance of a digital circuit
                                              without requiring simulation,
                                              ensuring it meets speed and timing
                                              requirements.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Timing Verification
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Path Analysis{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Clock Domain Crossing
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingTwelve">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseTwelve"
                                    aria-expanded="false"
                                    aria-controls="collapseTwelve"
                                  >
                                    <h5 class="py-2">
                                      12.Floor Planning s (Self-Paced)(12
                                      Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseTwelve"
                                  class="collapse"
                                  aria-labelledby="headingTwelve"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            What is FLOOR PLANNING ?
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Several criteria used to measure the
                                            quality of Floorplans
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Inputs required
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            How to qualify for Import Design?
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            What is required to come with a good
                                            floor plan?
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            How to do floor planning for
                                            planning Macros?
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Floorplan Steps
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            How to qualify for Floorplan?
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Power planning
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Analyze QOR, Timing, congestion,
                                            area, and power
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Hierarchical floor planning and
                                            design partitioning
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            I/O planning and RDL routing
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Floor planning involves the
                                              initial layout and organization of
                                              components on a chip, considering
                                              factors like placement of
                                              functional blocks, routing
                                              channels, and signal integrity to
                                              optimize chip performance and area
                                              utilization.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Component Placement{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Routing Considerations{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Hierarchical Organization{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingThirteen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseThirteen"
                                    aria-expanded="false"
                                    aria-controls="collapseThirteen"
                                  >
                                    <h5 class="py-2">
                                      13.Placement (9 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseThirteen"
                                  class="collapse"
                                  aria-labelledby="headingThirteen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Different criteria driving the
                                            placement process
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Different tasks in placement
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Goals of placement
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Sanity checks before placement
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Placement algorithms
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Pre-placement
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Optimization techniques
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Placement qualification
                                          </li>
                                          <li>
                                            Analyze QOR, Timing, congestion,
                                            area, and power
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Placement is the process of
                                              determining the precise locations
                                              of individual components within a
                                              chip's layout, aiming to minimize
                                              signal delays, optimize routing
                                              efficiency, and meet timing and
                                              area constraints.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Optimization Objectives{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Hierarchical Placement{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Global and Detailed Placement
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingFourteen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFourteen"
                                    aria-expanded="false"
                                    aria-controls="collapseFourteen"
                                  >
                                    <h5 class="py-2">
                                      14. Clock Tree Synthesis (9 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFourteen"
                                  class="collapse"
                                  aria-labelledby="headingFourteen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Sanity checks need to be done before
                                            CTS Preparations
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Difference between High Fan-out Net
                                            Synthesis (HFNS) & Clock Tree
                                            Synthesis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Concurrent Clock and Data
                                            Optimization
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            What is the difference between clock
                                            buffer and normal buffer?
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            CTS Goals
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Clock Tree Design Rule Constraints
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Clock Tree Exceptions
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            NDRs
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Analyze QOR, Timing, congestion,
                                            area, and power
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              CTS is the process of designing
                                              and implementing a hierarchical
                                              distribution network for clock
                                              signals throughout a chip,
                                              ensuring uniform clock
                                              distribution, minimal skew, and
                                              meeting timing requirements for
                                              synchronous digital circuits.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Clock Distribution{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Hierarchy{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Optimization{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingFifteen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFifteen"
                                    aria-expanded="false"
                                    aria-controls="collapseFifteen"
                                  >
                                    <h5 class="py-2">
                                      15.Design Synthesis & PDKs (Self-Paced)(9
                                      Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFifteen"
                                  class="collapse"
                                  aria-labelledby="headingFifteen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to Synthesis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Physical Synthesis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Standard input and output files for
                                            a Synthesis tool
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            How to Write SDC?
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            How to analyse the synthesis report?
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            LEC{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            UPF{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            PDK (.lib .lef .tf .ptf .rlc .ndm
                                            .dlib Milkyway, tlup files )
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Tool related Technology files
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Design synthesis is the process of
                                              translating a high-level hardware
                                              description into a gate-level
                                              netlist, optimizing for area,
                                              power, and timing constraints.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Logic Optimization
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              PDK Integrations{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Timing Analysis{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingSixteen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseSixteen"
                                    aria-expanded="false"
                                    aria-controls="collapseSixteen"
                                  >
                                    <h5 class="py-2">
                                      16. Physical Verification & Sign-off (11
                                      Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseSixteen"
                                  class="collapse"
                                  aria-labelledby="headingSixteen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Design Rule Check (DRC)
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Typical DRC rules
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Layout versus Schematic (LVS)
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            How LVS works
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Commonly faced LVS issues
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            IR Drop Analysis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Electro Migration
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Methods to fix EM
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Timing ECO's for each type of
                                            violation
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            PERC
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Signoff
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Physical verification involves
                                              checking the design's adherence to
                                              manufacturing rules, including
                                              Design Rule Checking (DRC) and
                                              Layout Versus Schematic (LVS)
                                              validation.{" "}
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Design Rule Checking (DRC){" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Layout vs. Schematic (LVS)
                                              Checking{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Sign-off Analysis
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingSeventeen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseSeventeen"
                                    aria-expanded="false"
                                    aria-controls="collapseSeventeen"
                                  >
                                    <h5 class="py-2">
                                      17. Routing(8 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseSeventeen"
                                  class="collapse"
                                  aria-labelledby="headingSeventeen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Sanity checks
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Routing flow (Global routing, Track
                                            assignment, Detailed Routing, Search
                                            and repair)
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Goals of Routing
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Routing Constraints
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Clock domain and Variations
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Post Routing Optimization
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Analyze QOR, Timing, congestion,
                                            area, and power
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Timing Closure
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Routing is the process of
                                              connecting the placed components
                                              on a chip with wires, ensuring
                                              signal integrity, meeting timing
                                              constraints, and optimizing for
                                              minimal congestion and efficient
                                              use of routing resources.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Interconnect Creation{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Optimization{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Design Rule Compliance{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingEighteen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseEighteen"
                                    aria-expanded="false"
                                    aria-controls="collapseEighteen"
                                  >
                                    <h5 class="py-2">
                                      18. Business Communication (6 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseEighteen"
                                  class="collapse"
                                  aria-labelledby="headingEighteen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Transition from College to Corporate
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Interpersonal skills and
                                            Presentation Skills
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Email Etiquette
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Resume writing
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Interview Skills: Group Discussion &
                                            HR Round Preparation
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Mockup Interviews Technical/HR
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Business communication involves
                                              the exchange of information within
                                              and outside an organization to
                                              achieve business goals, including
                                              clear, concise, and effective
                                              written, verbal, and digital
                                              communication methods.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Clarity and Conciseness{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Medium and Channels{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Interpersonal Skills
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingNineteen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseNineteen"
                                    aria-expanded="false"
                                    aria-controls="collapseNineteen"
                                  >
                                    <h5 class="py-2">
                                      19. Signal Integrity and Cross-Talk Issues
                                      (6 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseNineteen"
                                  class="collapse"
                                  aria-labelledby="headingNineteen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Signal Integrity
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Concerns addressed by signal
                                            integrity
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Factors affecting signal integrity
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Cross Talk Noise
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Cross Talk Delay
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Antenna Effects
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Signal integrity refers to the
                                              quality and reliability of
                                              electrical signals in a circuit,
                                              while cross-talk issues occur when
                                              unwanted interference from
                                              neighboring signals degrades
                                              signal quality, affecting circuit
                                              performance.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Signal Integrity{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Crosstalk{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Mitigation Techniques{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingTwenty">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseTwenty"
                                    aria-expanded="false"
                                    aria-controls="collapseTwenty"
                                  >
                                    <h5 class="py-2">
                                      20. Industry Standard Projects (9 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseTwenty"
                                  class="collapse"
                                  aria-labelledby="headingTwenty"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Design specification analysis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Creating the design architecture
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Partitioning the design
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            RTL coding in Verilog
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            RTL functional verification
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            RTL Synthesis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Place & Route the netlist
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Static Timing Analysis & ECO
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Physical Verification & Signoff
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Industry standard projects adhere
                                              to established protocols,
                                              guidelines, and best practices to
                                              ensure compatibility, reliability,
                                              and performance across various
                                              domains in engineering and
                                              technology.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Adherence to Standards{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Best Practices{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Collaboration and Tool Usage{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="col-md-4">
                    <div
                      class=" form-column"
                      style={{
                        boxShadow: "0px 0px 1px 1px lightblue",
                        borderRadius: "10px",
                        borderTop: "10px solid #662d91",
                        paddingBottom: "3px",
                        width: "100%",
                        marginTop: "155px",
                      }}
                    >
                      <div class="form-inner" style={{ margin: "20px" }}>
                        <form
                          // method="post"
                          // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                          id="contact-form"
                          class="default-form"
                        >
                          <div class=" clearfix">
                            <h4 style={{ textAlign: "center" }}>
                              Free Career Counselling
                            </h4>
                            <p style={{ textAlign: "center" }}>
                              We are happy to help you 24/7
                            </p>

                            <div class=" form-group mt-3">
                              <input
                                type="text"
                                // name="username"
                                placeholder="Your Name *"
                                required=""
                              />
                            </div>

                            <div class=" form-group">
                              <input
                                type="text"
                                // name="phone"
                                required=""
                                placeholder="Phone *"
                              />
                            </div>
                            <div class=" form-group">
                              <input
                                type="text"
                                // name="phone"
                                required=""
                                placeholder="Email Id *"
                              />
                            </div>
                            {/* <div class=" form-group">
                    <input
                      type="text"
                      // name="location"
                      placeholder="Location "
                    />
                  </div>
                  <div class=" form-group">
                    <input
                      type="text"
                      // name="subject"
                      required=""
                      placeholder="Subject"
                    />
                  </div>
                  <div class=" form-group">
                    <textarea
                      // name="message"
                      placeholder="Message ..."
                    ></textarea>
                  </div> */}
                            <div class=" form-group message-btn centred">
                              <button
                                class="theme-btn-one"
                                type="submit"
                                // name="submit-form"
                              >
                                Enroll Now
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      {/* <!-- React Course-details end --> */}
    </div>
  );
};

export default VLSIphysicaldesign;
