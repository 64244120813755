import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Blog from "../Blog/Blog";
import Login from "../Blog/Login/Login";
import Aboutus from "../Blog/Pages/Aboutus";
import Welcome from "../Blog/Pages/Welcome";
import Training from "../Blog/Pages/Training";
import Contacts from "../Blog/Pages/Contacts";
import UIUXdetails from "../Blog/Pages/Coursedetails/CourseDetails/UIUXdetails";
import Reactdetails from "../Blog/Pages/Coursedetails/CourseDetails/Reactdetails";
import ReactNativedetails from "../Blog/Pages/Coursedetails/CourseDetails/ReactNativedetails";
import Angulardetails from "../Blog/Pages/Coursedetails/CourseDetails/Angulardetails";
import Netdetails from "../Blog/Pages/Coursedetails/CourseDetails/Netdetails";
import CLanguagedetails from "../Blog/Pages/Coursedetails/CourseDetails/CLanguagedetails";
import SqlPgsqldetails from "../Blog/Pages/Coursedetails/CourseDetails/SqlPgsqldetails";
import JavaSpringbootdetails from "../Blog/Pages/Coursedetails/CourseDetails/JavaSpringbootdetails";
import Adminprotectedroute from "../Authentication/Adminprotectedroute";
import Services from "../Blog/Pages/Services/Services";
import Jobseekers from "../Blog/Pages/Jobseekers";
import Temporarystaffing from "../Blog/Pages/Trainings/Temporarystaffing";
import Directhire from "../Blog/Pages/Trainings/Directhire";
import Contracthire from "../Blog/Pages/Trainings/Contracthire";
import Payrolling from "../Blog/Pages/Trainings/Payrolling";
import Traininghire from "../Blog/Pages/Trainings/Traininghire";
import ExecutiveSearch from "../Blog/Pages/Trainings/ExecutiveSearch";
import ProfessionalCertification from "../Blog/Pages/ProfessionalCertificationTraining/ProfessionalCertification";
import Courses from "../Blog/Courses/Courses";
import ShorttermInternship from "../Blog/Pages/ProfessionalCertificationTraining/shortterm/ShorttermInternship";
import LongtermInternship from "../Blog/Pages/ProfessionalCertificationTraining/longterm/LongtermInternship";
import PlacementGuarantee from "../Blog/Pages/ProfessionalCertificationTraining/PlacementGuaranteeProgram/PlacementGuarantee";
import Register from "../Blog/Login/Register";
import Home from "../admin/components/home/Home";
import Employers1 from "../admin/pages/Employers";
import Course1 from "../admin/pages/Courses";
import Jobseeker from "../admin/pages/Jobseeker";
import Errorpage from "../Blog/Errorpage";
import Addcourse from "../admin/pages/Addforms/Addcourse";
import Addemployer from "../admin/pages/Addforms/Addemployer";
import Addjobseeker from "../admin/pages/Addforms/Addjobseeker";
import VLSIphysicaldesign from "../Blog/Pages/Coursedetails/VLSIphysicaldesign";
import VLSIanaloglayout from "../Blog/Pages/Coursedetails/VLSIanaloglayout";
import VLSIdesignverification from "../Blog/Pages/Coursedetails/VLSIdesignverification";
import VLSIembeddedsystems from "../Blog/Pages/Coursedetails/VLSIembeddedsystems";
import VLSICertificateContent from "../Blog/VLSI certification program/VLSICertificateContent";
import UseScrollToTop from "../../UseScrollToTop";
import Header from "../Blog/Header/Header";
import Footer from "../Blog/Footer/Footer";
import AICourses from "../Blog/Pages/aiCourses/AICourses";
// import AICourses from "../Blog/Pages/aiCourses/AICourses";
function Rootstock() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to show the preloader
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // 1 seconds

    // Clear the timer when component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleCancelPreloader = () => {
    setIsLoading(false); // Close the preloader
  };

  return (
    <Router>
       <UseScrollToTop/>
      <Header/>
      <Routes>
        <Route path="/" element={<Blog />} />
        <Route path="*" element={<Errorpage />} />
        <Route path="/about-zeusCrew" element={<Aboutus />} />
        <Route path="/trainings" element={<Training />} />
        <Route path="/products" element={<Courses />} />
        <Route path="/employers" element={<Welcome />} />
        <Route path="/services" element={<Jobseekers />} />
        <Route path="/our-staffing-services" element={<Services />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/UI-UX-Course-Details" element={<UIUXdetails />} />
        <Route path="/react-Course-Details" element={<Reactdetails />} />
        <Route path="/temporary-staffing" element={<Temporarystaffing />} />
        <Route path="/contract-hire" element={<Contracthire />} />
        <Route path="/direct-hire" element={<Directhire />} />
        <Route path="/payrol-hire" element={<Payrolling />} />
        <Route path="/carporate-training" element={<Traininghire />} />
        <Route path="/executive-search" element={<ExecutiveSearch />} />
        <Route path="/VLSI-physical-design-details" element={<VLSIphysicaldesign />} />
        <Route path="/VLSI-analog-layout-details" element={<VLSIanaloglayout />} />
        <Route path="/VLSI-design-verification-details" element={<VLSIdesignverification />} />
        <Route path="/VLSI-embedded-systems-details" element={<VLSIembeddedsystems />} />
        <Route
          path="/professional-certification-training"
          element={<ProfessionalCertification />}
        />
        <Route
          path="/ai"
          element={<AICourses/>}
        />
        <Route
          path="/short-term-Internship-training"
          element={<ShorttermInternship />}
        />
        <Route
          path="/long-term-Internship-training"
          element={<LongtermInternship />}
        />
        <Route
          path="/placement-guarantee-program"
          element={<PlacementGuarantee />}
        />
        <Route
          path="/ReactNative-Course-Details"
          element={<ReactNativedetails />}
        />
        <Route path="/Angular-Course-Details" element={<Angulardetails />} />
        <Route path="/.Net-Course-Details" element={<Netdetails />} />
        <Route
          path="/MERN-Full-Stack-Course-Details"
          element={<CLanguagedetails />}
        />
        <Route
          path="/JAVA-Full-Stack-Course-Details"
          element={<JavaSpringbootdetails />}
        />
        <Route path="/DevOps-Course-Details" element={<SqlPgsqldetails />} />
        <Route path="/VLSI-Certification-training-program" element={<VLSICertificateContent />} />

        <Route path="/home/*" element={<Home />} >
        <Route path="employers1" element={<Employers1 />} />
        <Route path="job-seekers1" element={<Jobseeker />} />
        <Route path="courses1" element={<Course1 />} />
        <Route path="add-courses" element={<Addcourse />} />
        <Route path="add-employers" element={<Addemployer />} />
        <Route path="add-jobseekers" element={<Addjobseeker />} />
        </Route>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default Rootstock;
