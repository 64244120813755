import React, { useState } from "react";
import "./contactus.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ROOT_URL from "../../Utilities/apiconfig";

const Contacts = () => {
  const [formData, setformData] = useState({
    email: "",
    firstname: "",
    lastname: "",
    phonenumber: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${ROOT_URL}/contactpost`, formData)
      .then((response) => {
        console.log(response.data.message);

        setformData({
          email: "",
          firstname: "",
          lastname: "",
          phonenumber: "",
          message: "",
        });
        // Display toast message for 5 seconds
        toast.success(response.data.message, { autoClose: 3000 });
      })
      .catch((error) => {
        console.error("Error logging in :", error);
        // Display toast message for login failure
        toast.error(error.response.data.message, { autoClose: 3000 });
      });
  };

  return (
    <div>

      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <div class="title-box centred" style={{marginTop:"-150px", marginBottom:"0px"}}>
              <h1>Get In Touch</h1>
              <p>Smarter Business Solutions. Better Customer Relations.</p>
            </div>
            
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- contactinfo-section --> */}
      <section class="contactinfo-section contact-page-section">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-6 col-md-12 col-sm-12 content-column">
              <div id="content_block_6">
                <div class="content-box">
                  <div class="sec-title">
                    <span class="top-title">Endless Opportunities</span>
                    <h2>It’s Your Turn</h2>
                    <p>
                    Stay up-to-date with the latest news, job opportunities, and training updates by following us. Connect with us on info@zeuscrew.in to join the conversation.
                    </p>
                  </div>
                  <ul class="info-list clearfix">
                    <li>
                      <figure class="icon-box">
                        <img src="assets/images/icons/icon-39.png" alt="" />
                      </figure>
                      <div class="inner">
                        <h4>Location</h4>
                        <p>
                        #117,118, 6Th Floor, G & F Block, Neil Tower, Neil Rao Towers Road No.3, Vijayaanagar, EPIP Phase I, Whitefeild,Bangalore-560066,
                        </p>
                      </div>
                    </li>
                    <li>
                      <figure class="icon-box">
                        <img src="assets/images/icons/icon-40.png" alt="" />
                      </figure>
                      <div class="inner">
                        <h4>Call or Email</h4>
                        <p>
                          <a href="tel:9032160768">(+91) 9032160768</a>
                          <br />
                          <a href="mailto:info@zeuscrew.in">info@zeuscrew.in</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <figure class="icon-box">
                        <img src="assets/images/icons/icon-41.png" alt="" />
                      </figure>
                      <div class="inner">
                        <h4>Office Hours</h4>
                        <p>
                          Mon - Sat: 8.00am to 9.00pm
                          <br />
                          Sunday: Closed
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 form-column">
              <div class="form-inner">
                <h2>What are you looking for?</h2>
                <form
                  // method="post"
                  // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                  id="contact-form"
                  class="default-form"
                >
                  <div class="row clearfix">
                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                      <input
                        type="text"
                        // name="username"
                        placeholder="Your Name *"
                        required=""
                      />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                      <input
                        type="email"
                        // name="email"
                        placeholder="Email Address *"
                        required=""
                      />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                      <input
                        type="text"
                        // name="phone"
                        required=""
                        placeholder="Phone *"
                      />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                      <input
                        type="text"
                        // name="location"
                        placeholder="Location "
                      />
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                      <input
                        type="text"
                        // name="subject"
                        required=""
                        placeholder="Subject"
                      />
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                      <textarea
                        // name="message"
                        placeholder="Message ..."
                      ></textarea>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
                      <button
                        class="theme-btn-one"
                        type="submit"
                        // name="submit-form"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- contactinfo-section end --> */}
    </div>
  );
};

export default Contacts;
