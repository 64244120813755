import React from "react";
import { Link } from "react-router-dom";
import "./Training.css";

const Training = () => {
  return (
    <>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-120px" }}>
                  <div
                    class="col-lg-6 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white",fontWeight:"300" }}>Upskill Your Organization</h2><h3 style={{color:"white"}}>with the World's #1 Online Bootcamp</h3>
                          <p style={{ color: "black" }}>
                          Unlock Your Team's Potential Role-aligned learning paths that map digital skills to desired learner outcomes, 
                          A roadmap for readying key roles in your organization for business in the digital age.
                          </p>{" "}
                          <br /> <br /> 
                          <div className="row ml-1 pb-2">
                            <Link
                              to=""
                              className="class_link"
                            >
                              <div className="viewmore p-4 m-1 ">
                                <a href="">Get Hired</a>
                              </div>
                            </Link>
                            <Link
                              to=""
                              className="class_link"
                            >
                              <div className="booknow p-4 m-1 ">
                                <a href="">Talk to Our Advisor</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/banner/corporate-training.jpg"></img>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
            
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- service-details --> */}
      <section class="service-details">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-8 col-md-12 col-sm-12 content-side">
              <div class="service-details-content">
                <div class="content-one">
                  <div class="sec-title">
                    {/* <span class="top-title">Training</span> */}
                    <h2>CORPORATE TRAINING SOLUTIONS</h2>
                  </div>
                  <figure class="image-box">
                    <img src="assets/images/banner/corporate-training-1.jpg" alt="" />
                  </figure>
                  <div class="text">
                    <p>
                    Our training programs are designed to benefit employees across all management levels. Besides benefiting employers, it is also beneficial to employees as it allows them to acquire knowledge and skills that will help them advance professionally and personally. Choose a corporate training solution that fits your needs.
                    </p>
                    
                  </div>
                </div>
                <div class="content-two">
                  <div class="inner-box clearfix">
                    <div class="single-column">
                      <div class="content-box">
                        <h5>01</h5>
                        <h3>Individual Contributors</h3>
                        <div class="link">
                          <a href="temporary-staffing">
                            <i class="flaticon-right-arrow"></i>
                          </a>
                        </div>
                        <div class="icon-box">
                          <i class="flaticon-businessman-1"></i>
                        </div>
                      </div>
                      <div class="overlay-box">
                        <div class="icon-box">
                          <i class="flaticon-businessman-1"></i>
                        </div>
                        
                        <ul class="list clearfix">
                          <li> Stakeholder Management</li>
                          <li> Time Management</li>
                          <li> Conflict Management</li>
                          <li> Emergenetics Employee Profiling</li>
                          <li>Presentation & Communication Training for Employees</li>
                        </ul>
                        
                      </div>
                    </div>
                    <div class="single-column">
                      <div class="content-box">
                        <h5>02</h5>
                        <h3>Managers</h3>
                        <div class="link">
                          <a href="temporary-staffing">
                            <i class="flaticon-right-arrow"></i>
                          </a>
                        </div>
                        <div class="icon-box">
                          <i class="flaticon-human-resources"></i>
                        </div>
                      </div>
                      <div class="overlay-box">
                        <div class="icon-box">
                          <i class="flaticon-human-resources"></i>
                        </div>
                        <ul class="list clearfix">
                          <li> Manager Boot Camp for First Time Managers</li>
                          <li> ReceptionTeam Building Programist</li>
                          <li> Performance Management Training</li>
                          <li> Emergenetics: Meeting of the Minds</li>
                        </ul>
                        
                      </div>
                    </div>
                    <div class="single-column">
                      <div class="content-box">
                        <h5>03</h5>
                        <h3>Leaders</h3>
                        <div class="link">
                          <a href="temporary-staffing">
                            <i class="flaticon-right-arrow"></i>
                          </a>
                        </div>
                        <div class="icon-box">
                          <i class="flaticon-customer-service"></i>
                        </div>
                      </div>
                      <div class="overlay-box">
                        <div class="icon-box">
                          <i class="flaticon-customer-service"></i>
                        </div>
                        <ul class="list clearfix">
                          <li> Leadership Development Programs</li>
                          <li> Leadership Impact 360</li>
                          <li> Emotional Intelligence Training</li>
                          <li> trategic Alignment Workshop</li>
                          <li>Human Synergistics Cultural Assessment</li>
                        </ul>
                        
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="content-four">
                  <div class="upper-box">
                    <h3>Next-Gen Training for High ROI:</h3>
                    <p>
                    Curated by industry experts, our corporate training in India aim to impart 
                    industry-recognized best corporate training solutions to the enterprise workforce 
                    to help develop skills needed to prepare for future business challenges and help drive better business outcomes.

                    </p>
                  </div>
                  <div class="inner-box">
                    <div class="row clearfix">
                      <div class="col-lg-6 col-md-6 col-sm-12 column">
                        <ul class="list-item clearfix">
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-short-tie"></i>
                            </div>
                            <h5>Certified In-House Trainers</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                We proudly house 20 in-house trainers. 
                                Our AWS corporate training in India is led by best certified corporate trainers
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-money-bag"></i>
                            </div>
                            <h5>Future-Ready Workforce Development</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Our training is curated by top corporate trainers in India who have expertise in providing 
                                solutions to not just be competitive in the industry today, but also overcome business challenges tomorrow.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-google-maps"></i>
                            </div>
                            <h5>Tailored Learning Sessions</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Address diverse learning needs with customized, need-based sessions catering to individual learning pace and requirements.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 column">
                        <ul class="list-item clearfix">
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-hourglass"></i>
                            </div>
                            <h5>Hands-On Labs and Projects</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Engage in extensive hands-on learning, real-world cases, and optional hackathons to enhance problem-solving skills.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-lightbulb"></i>
                            </div>
                            <h5>Continuous Assessment and Reporting</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Conduct thorough employee skill assessments, providing insightful reports at every stage.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-google-maps"></i>
                            </div>
                            <h5>Training for All Employment Levels</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                From freshers to corporate leaders, our corporate training in India caters to all levels, ensuring optimal outcomes for your workforce.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="content-five">
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div class="service-sidebar">
                <div class="sidebar-widget categories-widget">
                  <ul class="categories-list clearfix">
                    <li>
                      <a href="temporary-staffing">Temprory Staffing</a>
                    </li>
                    <li>
                      <a href="direct-hire">Direct Hire</a>
                    </li>
                    <li>
                      <a href="contract-hire">Contract to Hire</a>
                    </li>
                    <li>
                      <a href="payrol-hire">Payrolling</a>
                    </li>
                    <li>
                      <a href="training-hire" class="active">
                        Corporate Training
                      </a>
                    </li>
                    <li>
                      <a href="executive-search">Executive Search</a>
                    </li>
                  </ul>
                </div>
                <div class="sidebar-widget recruitment-widget">
                  <div class="widget-content">
                    <div class="text">
                      <div
                        class="pattern-layer"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-43.png)",
                        }}
                      ></div>
                      <figure class="image-box">
                        <img src="assets/images/resource/men-1.png" alt="" />
                      </figure>
                      <h3>Recruitment Solutions for All Industries...</h3>
                    </div>
                    <div class="link">
                      <a href="">
                        <i class="flaticon-right-arrow"></i>Place a Job Order
                      </a>
                    </div>
                  </div>
                </div>
                <div class="sidebar-widget subscribe-widget">
                  <h3>Reach Out</h3>
                  <form
                    // action="trainings"
                    // method="post"
                    class="subscribe-form"
                  >
                    <div class="form-group">
                      <label>Your Name</label>
                      <input
                        type="text"
                        // name="name"
                        placeholder="Mr/Mrs.xxxxx"
                        required=""
                      />
                    </div>
                    <div class="form-group">
                      <label>Email Address</label>
                      <input
                        type="email"
                        // name="name"
                        placeholder="example@domain.com"
                        required=""
                      />
                    </div>
                    <div class="form-group message-btn">
                      <button type="submit" class="theme-btn-one">
                        Send Your Interest
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- service-details end --> */}

    </>
  );
};

export default Training;
